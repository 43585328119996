import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import Table from "/components/shared/table";

function InvoicesTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  customerFilter,
  statusFilter,
  handleRowClick,
  setNotice,
  getIndicatorColor,
  selectedRowIndex,
}) {
  const filterSetup = useMemo(
    () => [
      {
        columnName: "customer_name",
        filterValue: customerFilter,
      },
      {
        columnName: "upload_status",
        filterValue: statusFilter,
      },
    ],
    [customerFilter, statusFilter],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("name", {
        header: "Name",
        sorting: false,
        cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
      }),
      columnHelper.accessor("row_count", {
        header: "Row Count",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorColor(info.getValue())}`} />
            {info.getValue()}
          </div>
        ),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("matched_percentage", {
        header: "Matched %",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("partially_matched", {
        header: "Partially Matched",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("not_matched", {
        header: "Not Matched",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("issues", {
        header: "Issues",
        cell: (info) => info.getValue()?.length || "--",
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("date", {
        header: "Submitted Date",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default InvoicesTable;
