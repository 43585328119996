import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MfaVerifyBillboard from "./MfaVerifyBillboard";
import Notice from "/components/shared/Notice";
import Http from "./http";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const MfaVerify = (props) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: null,
  });

  const handleSubmitCode = (e) => {
    e.preventDefault();

    Http.post(props.url, { code: e.currentTarget.elements.code.value }).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        location.href = res.data.path;
      } else {
        setNotice({ kind: "error", open: true, message: "Error - " + res.data.notice });
      }
    });
  };

  const handleResendCode = () => {
    Http.post("/mfa/email/resend").then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        setNotice({ kind: "success", open: true, message: res.data.notice });
      } else {
        setNotice({ kind: "error", open: true, message: "Error - " + res.data.notice });
      }
    });
  };

  const renderResendButton = () => {
    if (props.kind == "Email") {
      return (
        <div className="t--link" onClick={handleResendCode}>
          Can't find the email? Send a new email.
        </div>
      );
    }
  };

  const renderBackup = () => {
    if (props.kind != "Email") {
      return (
        <a href={"/mfa/email/verify" + window.location.search}>
          <div className="t--link">
            {props.kind} unavailable? Receive your one-time code via email.
          </div>
        </a>
      );
    }
  };

  const renderForm = () => {
    return (
      <form method="POST" onSubmit={handleSubmitCode}>
        <input
          type="hidden"
          name="authenticity_token"
          value={document.querySelector("[name=csrf-token]").content}
        />
        <div className="form__section">Enter {props.kind} Code</div>
        <div className="form__group">
          <CustomTextField
            label="One-Time Code"
            margin="normal"
            variant="outlined"
            id="code"
            name="code"
            size="small"
            fullWidth
            required
            autoFocus
          />
          {renderBackup()}
          {renderResendButton()}
        </div>
        <div className="form__group">
          <button className="btn btn--fw" type="submit">
            Verify
          </button>
        </div>
      </form>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-6 bg-light-gray">
          <MfaVerifyBillboard kind={props.kind} />
        </div>
        <div className="col-6">
          <div className="form__registration animated fadeInUp--sm">{renderForm()}</div>
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default MfaVerify;
