import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import _ from "lodash";
import useOutsideClick from "/components/utils/useOutsideClick";
import IdSearchModal from "./modals/id_search_modal";

const IdSearch = ({ icon, label, visible, mandatory, value, handleVisibility, handleChange }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value == "" || value == []) setOpen(true);
  }, [value]);

  const handleRemove = () => {
    handleChange([]);
    handleVisibility(label.toLowerCase(), false);
    setOpen(true);
  };

  const renderRemove = () => {
    return (
      <div className="filter__item__remove button button--icon-tiny" onClick={() => handleRemove()}>
        <Close style={{ height: 14, width: 14 }} />
      </div>
    );
  };

  const renderDialog = () => {
    if (open) {
      return <IdSearchModal setOpen={setOpen} submit={(v) => handleChange(v)} />;
    }
  };

  if (!mandatory && !visible) {
    return null;
  }

  return (
    <>
      <div className="filter__item" onClick={() => setOpen(!open)}>
        <div className="filter__item__label">
          <span className="material-symbols-outlined">{icon}</span>
          {label}
        </div>

        <div className="filter__item__type">SEARCHED: </div>
        <div className="filter__item__value">{value?.length}</div>
        {!mandatory && renderRemove()}
      </div>
      {renderDialog()}
    </>
  );
};

export default IdSearch;
