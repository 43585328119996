import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { alpha, styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";

const useStyles = styled(Snackbar)({
  root: {
    maxWidth: "50%",
  },
});

export default function FlashMessage(props) {
  const [state, setState] = React.useState({
    open: false,
  });

  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        setState({ ...state, open: true });
      }, 1500);
    } else {
      setState({ ...state, open: true });
    }
  }, []);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={state.open}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={props.kind}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
