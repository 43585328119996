import React from "react";
import { useForm } from "react-hook-form";
import ResetBillboard from "./ResetBillboard";
import FormInput from "/components/shared/FormInput";

const RequestPasswordReset = () => {
  const {
    register,
    formState: { isValid, errors },
  } = useForm({ mode: "onChange" });

  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <ResetBillboard />
      </div>
      <div className="col-6">
        <div className="form__registration animated fadeInUp--sm">
          <form id="password-reset-form" className="" method="POST" action="/password_resets">
            <div className="form__group">
              <div className="form__section">Reset Your Password</div>
              <p className="form__group__note">
                Enter your email address to request a password reset.
              </p>
              <FormInput
                type="email"
                label=""
                placeholder="Email"
                name="email"
                autoFocus={true}
                errors={errors}
                register={register}
                registerOptions={{
                  required: "Field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
              />
            </div>
            <div className="form__group">
              <input
                type="hidden"
                name="authenticity_token"
                value={document.querySelector("[name=csrf-token]").content}
              />
              <button
                type="submit"
                className={"btn btn--fw " + (isValid ? "" : "btn--disabled")}
                disabled={!isValid}
              >
                Send Reset Instructions
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
