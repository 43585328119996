export const fetchWrapper = {
  vault: GetVault,
  createFieldMapping: createFieldMapping,
  updateFieldMapping: updateFieldMapping,
  deleteFieldMapping: deleteFieldMapping,
};

function GetVault() {
  const url = `/api/vault`;

  const requestOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function createFieldMapping(type, mapping) {
  const url = `/field_mappings/${type}`;

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({
      field_mappings: { ...mapping.mappings, name: mapping.name },
    }),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function updateFieldMapping(type, mapping) {
  const url = `/field_mappings/${type}/${mapping.id}`;

  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({
      field_mappings: { ...mapping.mappings, name: mapping.name },
    }),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteFieldMapping(type, id) {
  const url = `/field_mappings/${type}/${id}`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((resObj) => {
    if (!response.ok) {
      const error = (resObj && resObj.message) || response.statusText;
      return Promise.reject(error);
    }

    return resObj;
  });
}
