import React from "react";

const HeaderCell = ({ header, title, viewOriginal, warnings, mapping, mapped }) => {
  // const headerValue = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

  const renderColumnMappingWarning = (columnName, columnTitle, columnMapping) => {
    // TODO: Needs refactor. Here and several other places, it would be helpful to have a reverse columnMapping for the original view

    const getHeaderValue = () => {
      if (!viewOriginal || !columnMapping || !columnMapping[columnName]) {
        return columnTitle;
      }

      return columnMapping[columnName];
    };

    return getHeaderValue();
  };

  return (
    <th className={viewOriginal && mapped ? "mapped" : ""} style={{ width: header.getSize() }}>
      {renderColumnMappingWarning(header.id, title, mapping)}
    </th>
  );
};

export default HeaderCell;
