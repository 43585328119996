import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const ResetBillboard = () => {
  return (
    <div className="billboard__container animated fadeInUp--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Request a Password Reset</div>
      <p>
        To request a password reset, enter your email address. If you have an existing account with
        Beacon, instructions will be sent to your provided email address on resetting your password.
        If you do not have an existing account, register{" "}
        <a className="t--link" href="/enrollments/registrations">
          here
        </a>
        .
      </p>
      <BillboardSupportFooter />
    </div>
  );
};

export default ResetBillboard;
