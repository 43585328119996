import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";

const Selector = (props) => {
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (!filters || !filters.entity || filters.entity == props.filters.entity) return;

    const link = document.createElement('a');
    //link.target = '_blank';
    //link.rel = 'noopener noreferrer';
    link.href = `/entities/${filters.entity}`;
    link.click();
  }, [filters]);

  return (
    <Filters
      filterCallback={setFilters}
      defaultFilters={props.filters}
      filterLists={props.filterLists}
      disabledFilters={{ date_range: true, submission: true }}
      mandatoryFilters={["entity"]}
    />
  );
};

export default Selector;
