import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

// Todo - Refactor and rename

const OriginalFileSelect = ({ attachments, originalViewId, setOriginalViewId }) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();

  const originalViewAttachment = originalViewId
    ? attachments.find((attachment) => attachment.id === originalViewId)
    : null;

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClick = (item) => {
    setOriginalViewId(item.id);
    setOpen(false);
  };

  const renderAttachmentName = () => {
    return originalViewAttachment?.name;
  };

  const renderDialog = () => {
    if (open) {
      return (
        <div ref={filterRef} className="dialog__container dialog--right">
          {renderItems()}
        </div>
      );
    }
  };

  const renderItems = () => {
    return _.map(attachments, (item, index) => {
      return (
        <div key={index} className="dialog__item" onClick={() => handleClick(item)}>
          <span className="material-symbols-outlined">list</span>
          {item.name}
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="button button--outlined" ref={filterRef} onClick={() => setOpen(!open)}>
        <span className="material-symbols-outlined">description</span>
        <span style={{ marginRight: 5 }}>File:</span>
        {renderAttachmentName()}
      </div>
      {renderDialog()}
    </div>
  );
};

export default OriginalFileSelect;
