import React, { useState } from "react";
import DesignationsTable from "./tables/designations_table";
import ShippingLocationsTable from "./tables/shipping_locations_table";
import MembersTable from "./tables/members_table";
import GridDetailSection from "./components/GridDetailSection";
import UserInviteModal from "/components/entities/modals/user_invite_modal";

import Tabs from "/components/shared/tabs";

const Show = ({ eligiblePharmacies, addresses, team_members, entity, access_role }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    { id: 1, label: "CE Shipping Locations" },
    { id: 2, label: "Eligible Contract Pharmacies" },
    { id: 3, label: "Users" },
  ];

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return <ShippingLocationsTable shippingAddresses={addresses} />;
      case 2:
        return <DesignationsTable pharmacies={eligiblePharmacies} />;
      case 3:
        return <MembersTable team_members={team_members} />;
      default:
        return <DesignationsTable />;
    }
  };

  const formatDate = (dateString) => {
    // This choice is a bit odd but it yields the YYYY-MM-DD format.
    return new Date(dateString).toLocaleDateString("fr-CA");
  };

  const renderDetailsGrid = (
    <>
      <div className="grid__details__section__info__container grid__details__section__info__container--mt">
        <GridDetailSection title="340B ID" value={entity.id_340b} />
        <GridDetailSection title="Entity Type" value={entity.type_code} />
        <GridDetailSection title="Primary Contact" value={entity.primary_contact_name} />
        <GridDetailSection title="Authorizing Official" value={entity.authorizing_official_name} />
      </div>
      <div className="grid__details__section__info__container grid__details__section__info__container--bb">
        <GridDetailSection title="Beacon Registration" value={formatDate(entity.beacon_registration_date)} />
        <GridDetailSection title="Bank Status" value={entity.status} />
        <GridDetailSection title="Administrators" value={entity.admin_count} />
        <GridDetailSection title="Users" value={entity.user_count} />
      </div>
    </>
  );

  return (
    <>
      <div className="container container--space-between">
        <div className="container container--align-center container--gap-md">
          <div className="avatar avatar__company avatar__company--no-hover">
            <div className="material-symbols-outlined">home_work</div>
          </div>
          <div>
            <div className="t--lg">{entity.name}</div>
            <div className="t--subtitle">{entity.address}</div>
          </div>
        </div>
        <div>
          {access_role === "admin" && (
            <div className="container container--end container--mb-md">
              <UserInviteModal id_340b={entity.id_340b} entity_name={entity.name} />
            </div>
          )}
        </div>
      </div>
      {renderDetailsGrid}
      <div style={{ marginTop: 20 }}>
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      {renderSelectedTab()}
    </>
  );
};

export default Show;
