import React, { useState, useMemo } from "react";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { ResponsiveBar } from "@nivo/bar";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const StatCards = ({
  stats,
  hideAffected = false,
  hideUnderReview = false,
  hideIssues = false,
}) => {
  return (
    <div
      className="reports__grid"
      style={{ marginTop: 20, gridTemplateRows: "auto" }}
    >
      {!hideAffected ? <TotolAffectedCard stats={stats} /> : null}
      {!hideUnderReview ? <UnderReviewCard stats={stats} /> : null}
      {!hideIssues ? <CommonIssuesCard stats={stats} /> : null}
    </div>
  );
};

const TotolAffectedCard = ({ stats }) => {
  const affected_claims_by_month = useMemo(
    () =>
      stats?.affected_claims_by_month?.map((item) => {
        return {
          month: item.month,
          count: item.count,
        };
      }),
    [stats]
  );

  const affected_claims = useMemo(
    () =>
      stats?.affected_claims_by_month?.reduce(
        (acc, item) => acc + item.total,
        0
      ),
    [stats]
  );

  return (
    <div className="div1">
      <div className="tile__well" style={{ padding: 0, border: "none" }}>
        <div className="tile flex flex-col item-center">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Total Claims Affected</div>
            </div>
          </div>
          <div
            className="flex__container flex__container__col item-start"
            style={{ gap: 0 }}
          >
            <div className="t-500 t--xl">{affected_claims}</div>
          </div>

          <div style={{ height: 200, width: 260 }}>
            {affected_claims_by_month && (
              <ResponsiveBar
                data={affected_claims_by_month}
                keys={["count"]}
                indexBy="month"
                colors={["#3246D3"]}
                enableLabel={false}
                enableGridX={false}
                enableGridY={false}
                axisLeft={false}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  truncateTickAt: 0,
                  enableLabel: true,
                  format: (x) => {
                    return x?.split("")[0];
                  },
                }}
                margin={{
                  right: 0,
                  bottom: 40,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UnderReviewCard = ({ stats }) => {
  const currencyFormat = (value) => {
    return value?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <div className="div2">
      <div
        className="tile__well"
        style={{ padding: 0, border: "none", height: "100%" }}
      >
        <div className="tile">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Under Review</div>
            </div>
          </div>
          <div className="flex flex-col item-start justify-start mt--2">
            <div className="t-500 t--xl">{stats.under_review?.total}</div>
            <div className="t--sm">This Month</div>
          </div>

          <div className="flex flex-col item-start justify-start mt--16">
            <div className="t-500 t--lg">
              {stats.under_review?.affected_claims}
            </div>
            <div className="flex">
              <div className="t--sm">Claims Affected</div>
            </div>
          </div>

          <div className="flex flex-col item-start justify-start mt--16">
            <div className="t-500 t--lg">
              {currencyFormat(stats?.under_review?.pending_payments)}
            </div>

            <div className="flex">
              <div className="t--sm">Pending payments</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonIssuesCard = ({ stats, show }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="div3">
      <div
        className="tile__well"
        style={{ padding: 0, border: "none", height: "100%" }}
      >
        <div className="tile">
          <div className="tile__header tile__header--spread">
            <div className="flex flex-col item-start">
              <div className="t--lg">Top Issues</div>
              <div
                className="t--sm t--gray mt-1"
                style={{ fontStyle: "italic" }}
              >
                Calculated based on current filter
              </div>
            </div>
          </div>
          <div className="flex__container flex__container--align-center flex-col">
            {stats.issue_tally
              ?.sort((a, b) => b.count - a.count)
              .slice(0, 4)
              .map((item) => (
                <div className="progress_bar__wrapper mt--16 flex-col item-start">
                  <div className="t--sm ml-2"> {item.issue.label} </div>
                  <div className="flex">
                    <div
                      className="progress_bar__container progress_bar__container--t-fixed"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="progress_bar__fill"
                        style={{
                          background: "#FF681F",
                          width: `${Math.trunc(
                            (item.count / stats.total_claims) * 100
                          )}%`,
                        }}
                      />
                    </div>
                    <div className="progress_bar__value">{`${Math.trunc(
                      (item.count / stats.total_claims) * 100
                    )}%`}</div>
                  </div>
                </div>
              ))}

            <div
              className="flex t--500 t--sm mt--16"
              aria-owns={open ? "reasoncodestooltip" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              All others{" "}
              {`   (${
                stats.issue_tally
                  ? Math.max(stats.issue_tally.length - 4, 0)
                  : 0
              })`}
            </div>

            <Popover
              id="reasoncodestooltip"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {stats.issue_tally
                ?.sort((a, b) => b.count - a.count)
                .slice(4, stats.issue_tally.length)
                .map((item) => (
                  <div className="t--sm p--5">{`${
                    item.issue.label
                  } (${Math.trunc(
                    (item.count / stats.total_claims) * 100
                  )}%)`}</div>
                ))}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCards;
