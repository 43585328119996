export class OriginalData {
  constructor(row, mapping, selected) {
    this.hasErrors = false;
    this.selected = selected;
    this.mapping = mapping;
    this.data = {...row, beacon_id: ""};

    this.columns_with_errors = {};
  }
}
