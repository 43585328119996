/**
 * Usage Example:
 *
 * import { Mixpanel } from '~components/utils/Mixpanel';
 *
 * Mixpanel.track('Registration Started');
**/
import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = document.body.dataset.mixpanelToken;

mixpanel.init(MIXPANEL_TOKEN);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export const MixpanelService = actions;
