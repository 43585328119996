import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    border: "1px solid #DEE5EB",
    boxShadow: "none",
    padding: theme.spacing(1),
  },
}));

const ToolTip = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleTooltipOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <button
        className={props.buttonClasses}
        disabled={props.disabled}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handlePopoverClose}
        onClick={props.action}
      >
        {props.buttonContent}
      </button>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ onMouseLeave: handlePopoverClose }}
        onClose={handlePopoverClose}
        onMouseLeave={handlePopoverClose}
        //disableRestoreFocus
      >
        <div className="t--sm p--3">{props.tooltipText}</div>
      </Popover>
    </>
  );
};

export default ToolTip;
