import React, { useEffect, useState } from "react";
import Http from "../http";
import AccountsCreditsTable from "../tables/accounts_credits_table";

const Credits = ({ path, isParentLoading, setSelectedCredit, setSelectedCreditIndex, selectedCreditIndex }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    Http.newFetchQuery(path).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, [path]);

  useEffect(() => {
    setSelectedCredit(data ? data[0] : null);
    setSelectedCreditIndex(0);
  }, [data]);

  const handleSelectCredit = (event) => {
    setSelectedCredit(event.row.original);
    setSelectedCreditIndex(event.row.index);
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "green";
      case "under review":
        return "orange";
      case "filled":
        return "green";
      case "allocated":
        return "green";
      case "reversed":
        return "red";
      default:
        return "blue";
    }
  };

  return (
    <>
      <AccountsCreditsTable
        isLoading={isLoading || isParentLoading}
        data={data}
        handleRowClick={handleSelectCredit}
        selectedRowIndex={selectedCreditIndex}
        getIndicatorColor={getIndicatorColor}
      />
    </>
  );
};

export default Credits;
