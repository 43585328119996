import React from "react";
import { tableHeaders } from "../schemas/schema_helper";


const DownloadTemplate = ({ type }) => {
  const onClick = () => {
    var headers = tableHeaders(type);

    if (headers.length === 0) {
      return;
    }

    var csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\r\n";

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${type}_template.csv`);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
  
  return (
    <div className="button button--outlined" onClick={onClick}>
      <div className="material-symbols-outlined">download</div>
      Download Template
    </div>
  );
}

export default DownloadTemplate;