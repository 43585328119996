import React, { useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
//import * as Turbo from "@hotwired/turbo"

import Table from "/components/shared/table";

function SubmissionsTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  customerFilter,
  statusFilter,
  setNotice,
  handleRowClick,
  selectedRowIndex,
}) {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const toggleRow = (index) => {
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const filterSetup = useMemo(
    () => [
      {
        columnName: "customer_name",
        filterValue: customerFilter,
      },
      {
        columnName: "upload_status",
        filterValue: statusFilter,
      },
    ],
    [customerFilter, statusFilter]
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("name", {
        header: "Submission",
        sorting: false,
        cell: (info) => (
          <>
            <div className="t--light">{info.row.original.kind}</div>
            <div>{info.getValue()}</div>
          </>
        ),
      }),
      columnHelper.accessor("entities", {
        header: "Entities",
        cell: (info) => {
          const isExpanded = expandedRowIndex === info.row.index;
          const data = isExpanded ? info.getValue().split(" ") : info.getValue().split(" ").slice(0, 19);

          return (
            <div className="entities-cell">
              {data.map((item, index) => {
                const isLastItem = index === data.length - 1;
                const trimmedItem = item.trim();

                // Check if item already ends with a comma, if not, add a comma (except for the last item)
                const formattedItem = !isLastItem
                  ? trimmedItem.endsWith(",")
                    ? trimmedItem
                    : trimmedItem + ","
                  : trimmedItem.replace(/,$/, "");

                return (
                  <span key={index} className="entity-item">
                    {formattedItem}
                  </span>
                );
              })}

              {info.getValue().split(" ").length > 19 && (
                <div className="show-more" onClick={() => toggleRow(info.row.index)} style={{ color: "#0560fd" }}>
                  {isExpanded ? "Show less" : "Show more"}
                </div>
              )}
            </div>
          );
        },
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("quantity", {
        header: "Qty",
        cell: (info) => (
          <>
            {info.getValue()} <span className="t--light">{info.row.original.units}</span>
          </>
        ),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("submitted_by", {
        header: "Submitted",
        cell: (info) => (
          <>
            <div>{info.getValue()}</div>
            <div className="t--light">{info.row.original.date}</div>
          </>
        ),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        minRows={6}
        disablePagination
      />
    </div>
  );
}

export default SubmissionsTable;
