import React, { useState } from "react";
import httpClient from "/components/utils/httpClient";
import FormInput from "/components/shared/FormInput";

const EmailForm = ({ setNotice }) => {
  const [displayAuthForm, setDisplayAuthForm] = useState(false);
  const [code, setCode] = useState("");

  const handleSendEmail = () => {
    httpClient
      .post("/mfa/email")
      .then((res) => {
        setNotice({ kind: "success", open: true, message: "One-Time Code successfully sent" });
        setDisplayAuthForm(true);
      })
      .catch((err) => {
        setNotice({ kind: "error", open: true, message: "Error - " + err.response.data.notice });
        setDisplayAuthForm(false);
      });
  };

  const handleSubmitCode = (e) => {
    e.preventDefault();

    httpClient
      .post("/mfa/email/verify", { code })
      .then((res) => (location.href = res.data.path))
      .catch((err) => {
        setNotice({ kind: "error", open: true, message: "Error - " + err.response.data.notice });
      });
  };

  const renderAuthForm = () => {
    if (displayAuthForm) {
      return (
        <form onSubmit={handleSubmitCode}>
          <div className="form__group">
            <div className="form__group__note">Enter the one-time code for verification.</div>
            <FormInput
              placeholder="One-Time Code"
              id="code"
              name="code"
              onChange={(e) => setCode(e.currentTarget.value)}
            />
          </div>
          <div className="form__group">
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className={`btn ${code.length > 0 ? "" : "btn--disabled"}`}
                disabled={code.length == 0}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="line-divider"></div>
        </form>
      );
    }
  };

  return (
    <>
      <div className="form__group">
        <div className="form__group__note mt-3">Click to send a one-time code to your email.</div>
        <div className="d-flex justify-content-end">
          <button onClick={handleSendEmail} className="btn btn--outline">
            Send Code
          </button>
        </div>
      </div>
      <div className="line-divider"></div>
      {renderAuthForm()}
    </>
  );
};

export default EmailForm;
