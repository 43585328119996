import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import _ from "lodash";
import {
  hasAtLeastOneLower,
  hasAtLeastOneUpper,
  hasAtLeastOneNumber,
  hasAtLeastOneSpecial,
  hasAtLeastTwelveChars,
} from "../utils/PasswordLength";
import SubmitButton from "~/components/shared/SubmitButton";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const Form = ({ user }) => {
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      },
    },
  });

  const watchedPassword = watch("user[password]", "");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  return (
    <form method="post" onSubmit={handleSubmit} action={`/activations/${user.activation_token}`}>
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector("[name=csrf-token]").content}
      />
      <div className="form__section">User Registration</div>
      <div className="form__group__multi">
        <div className="form__group">
          <label className="form__label">First Name</label>
          <CustomTextField
            disabled
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("user[first_name]", {
              required: "Field is required",
            })}
          />
        </div>
        <div className="form__group">
          <label className="form__label">Last Name</label>
          <CustomTextField
            disabled
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("user[last_name]", {
              required: "Field is required",
            })}
          />
        </div>
      </div>
      <div className="form__group">
        <div>
          <label className="form__label">Email</label>
          <CustomTextField
            disabled
            name="email"
            {...register("user[email]", {
              required: "Field is required",
            })}
            size="small"
            fullWidth={true}
            variant="outlined"
          />
        </div>
      </div>
      <div className="form__group">
        <div className="mb-2">
          <label className="form__label">Password</label>
          <CustomTextField
            name="password"
            placeholder="password"
            type="password"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("user[password]", {
              required: "Field is required",
              minLength: 12,
              pattern: {
                value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))/,
                message: "Password requirements not met",
              },
            })}
            error={errors.user ? _.has(errors.user, "password") : false}
          />
        </div>
        <div className="form__group__note" style={{ display: "flex", gap: 50 }}>
          <div>
            <ul>
              <li className={hasAtLeastOneLower(watchedPassword) ? "success" : null}>
                One lowercase character
              </li>
              <li className={hasAtLeastOneUpper(watchedPassword) ? "success" : null}>
                One uppercase character
              </li>
              <li className={hasAtLeastOneNumber(watchedPassword) ? "success" : null}>
                One number
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className={hasAtLeastOneSpecial(watchedPassword) ? "success" : null}>
                One special character
              </li>
              <li className={hasAtLeastTwelveChars(watchedPassword) ? "success" : null}>
                12 characters minimum
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="form__group">
        <div>
          <label className="form__label">Confirm Password</label>
          <CustomTextField
            placeholder="confirm password"
            name="confirm_password"
            type="password"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("user[password_confirmation]", {
              required: "Field is required",
              validate: (val) => {
                if (watchedPassword != val) {
                  return "Password does not match";
                }
              },
            })}
            error={errors.user ? _.has(errors.user, "password_confirmation") : false}
          />
          {errors.user?.password_confirmation && (
            <div className="form__error--message">{errors.user?.password_confirmation.message}</div>
          )}
        </div>
      </div>
      <div className="form__group">
        <SubmitButton title="Register" isDisabled={!isValid || isSubmitting} />
      </div>
    </form>
  );
};

export default Form;
