import React, { useState } from "react";
import UploadCofirmationModal from "./library/upload_confimation_modal";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Illustration, FileIcon } from "../images/svgs";

const FileUploadConfirmationModal = (props) => {
  const modalBody = () => {
    return (
      <div
        className="flex flex-col p--16 notify__info"
        style={{ background: "#F2F5F8", gap: 5 }}
      >
        <div
          className="grid w--100 p--8 notify__info notify__info--header"
          style={{
            gridTemplateColumns: "repeat(4, 1fr)",
            margin: 0,
          }}
        >
          <span className="t--sm t--500">Reason code</span>
          <span className="t--sm t--500">Details</span>
          <span className="t--sm t--500">Affected claims</span>
          <span className="t--sm t--500">Document</span>
        </div>
        <div
          className="grid w--100 p--8 notify__info item-center"
          style={{
            gap: 10,
            gridTemplateColumns: "repeat(4, 1fr)",
            background: "#fff",
            margin: 0,
          }}
        >
          <span className="t--sm"> {props.body.issue} </span>
          <span className="t--sm"> {props.body.details} </span>
          <span className="t--sm"> {props.body.claims_affected} </span>
          <span className="t--sm draw__tile draw__tile--border flex__container flex__container--space-between">
            <div className="flex__container">
                <FileIcon style={{ fontSize: 14 }} />
                <div className="t--500 mx-3 my-2">{props.body?.document?.name}</div>
            </div>
          </span>
        </div>
      </div>
    );
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end" style={{ gap: 20 }}>
        <button
          className="btn btn--light-outline btn--small"
          style={{ lineHeight: "inherit" }}
          onClick={props.secondaryAction}
        >
          Cancel
        </button>
        <button className="btn btn--small" onClick={props.primaryAction}>
          {props.isLoading ? (
            <div className="loading-indicator loading-indicator--no-margin"></div>
          ) : (
            "Confirm Dispute"
          )}
        </button>
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <UploadCofirmationModal
        title={props.title}
        subtitle={props.subtitle}
        footer={modalFooter()}
        body={modalBody()}
        showCloseIcon={true}
        setDisplayModal={props.setDisplayModal}
      />
    );
  }

  return null;
};

export default FileUploadConfirmationModal;
