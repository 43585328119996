import React, { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notice(props) {
  const [state, setState] = React.useState(props.details);

  useEffect(() => {
    setState(props.details);
  }, [props.details]);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "left" }} open={state.open} autoHideDuration={5000} onClose={handleClose}>
      <Alert elevation={5} variant="filled" onClose={handleClose} severity={state.kind}>
        {state.message}
      </Alert>
    </Snackbar>
  );
}
