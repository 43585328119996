import React, { useState } from "react";
import ConfirmationModal from "./library/confirmation_modal";
import BackgroundImage from "~/images/modal-background-image.png";

const ConfirmModal = (props) => {
  const modalBody = () => {
    return <div className="t--thin t--md t--grey mt--16 mb--32">{props.body}</div>;
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end" style={{ gap: 20 }}>
        <button className="button button--outlined" onClick={props.secondaryAction}>
          Cancel
        </button>

        <button
          className={props.isDeleting ? "button button--outlined" : "button"}
          disabled={props.isDeleting ? true : false}
          onClick={props.primaryAction}
        >
          {props.isDeleting ? (
            <div className="loading-state">
              <div className="loading"></div>
            </div>
          ) : (
            props.primaryActionText
          )}
        </button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex align-end justify-end mr--64" style={{ height: "100%" }}>
        <img src={BackgroundImage} height={"70px"} />
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <ConfirmationModal
        title={props.title}
        header={renderHeader()}
        footer={modalFooter()}
        body={modalBody()}
        width="500px"
        showCloseIcon={true}
        setDisplayModal={props.setDisplayModal}
      />
    );
  }

  return null;
};

export default ConfirmModal;
