import React, { useEffect, useState } from "react";
import _ from "lodash";
import EditEntity from "./modals/edit_entity";

const EnrollmentStatus = (props) => {
  return (
    <>
      <EditEntity {...props} />
    </>
  );
};

export default EnrollmentStatus;
