import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import _ from "lodash";
import useOutsideClick from "/components/utils/useOutsideClick";

const Selector = ({ icon, label, items, visible, mandatory, value, handleVisibility, handleChange }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value == "") setOpen(true);
  }, [value]);

  const handleRemove = () => {
    handleChange(label.toLowerCase(), "");
    handleVisibility(label.toLowerCase(), false);
    setOpen(true);
  };

  const renderDialog = () => {
    if (open) {
      return <div className="dialog__container dialog--right">{renderItems()}</div>;
    }
  };

  const renderItems = () => {

    const sortedItems = items.sort((a, b) => {
      const labelA = a.label.toLowerCase(); // Ensuring case-insensitive sorting
      const labelB = b.label.toLowerCase();
  
      if (labelA < labelB) {
        return -1;
      } else if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  
    return _.map(sortedItems, (item) => {
      return (
        <div key={item.label} className="dialog__item" onClick={() => handleChange(label.toLowerCase(), item.value)}>
          <span className="material-symbols-outlined">{icon}</span>
          {item.label}
        </div>
      );
    });
  };

  const renderRemove = () => {
    return (
      <div className="filter__item__remove button button--icon-tiny" onClick={() => handleRemove()}>
        <Close style={{ height: 14, width: 14 }} />
      </div>
    );
  };

  const handleClickOutside = () => {
    setOpen(false);
  };
  const ref = useOutsideClick(handleClickOutside);

  if (!mandatory && (!visible || !items)) {
    return null;
  }

  return (
    <>
      <div className="filter__item" onClick={() => setOpen(!open)} ref={ref}>
        <div className="filter__item__label">
          <span className="material-symbols-outlined">{icon}</span>
          {label}
        </div>

        <div className="filter__item__type">IS</div>
        <div className="filter__item__value">{_.find(items, { value: value })?.label}</div>
        {renderDialog()}
        {!mandatory && renderRemove()}
      </div>
    </>
  );
};

export default Selector;
