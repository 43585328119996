import React, { useMemo } from "react";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from "recharts";

const placeholderData = [{ a: 20, b: 10 }];

const Packages = ({ available, pending }) => {
  const data = available || pending ? [{ a: available, b: pending }] : placeholderData;

  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart layout="vertical" data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <XAxis type="number" hide={true} domain={[0, "dataMax"]} />
        <YAxis type="category" dataKey="a" hide={true} />
        <Bar
          dataKey="a"
          stackId="b"
          barSize={12}
          fill={available || pending ? "#0560FD" : "#EDF2F7"}
          radius={[6, 0, 0, 6]}
        />
        <Bar dataKey="b" stackId="b" barSize={12} fill="#DEE7EE" radius={[0, 6, 6, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Packages;
