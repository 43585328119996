import _ from "lodash";

export default class MappingValidations {
  static columnNames = {
    date_of_service: [
      "Date Of Service",
      "Fill Date",
      "date_of_service",
      "Dispensed",
      "Fill Sold Date",
      "Service Date",
      "Date of Service (Fill Date)",
      "Rx Fill Date",
      "DOS (Date of Service)",
      "Date Filled",
      "Dispense Date",
      "DOS",
    ],
    date_prescribed: [
      "Date Prescribed",
      "Rx Written Date",
      "date_prescribed",
      "Written Date",
      "Date Prescription Written",
      "Prescribed Date",
      "Date Written",
      "RX-Date Written",
      "Script Written Date",
      "Date RxWritten",
      "Date Rx Written",
    ],
    rx_number: [
      "Rx number",
      "Prescription Number",
      "Service Reference Number",
      "Rx Nbr",
      "rx_number",
      "Prescription_Number",
      "Rx #",
      "RX NBR",
    ],
    ndc: ["NDC", "Product", "Service ID", "NDC Number", "Dispensed NDC", "Dispensed Item NDC", "Dispensed NDC11"],
    quantity: [
      "Quantity",
      "Qty Disp",
      "Qnty",
      "Qty Dispensed",
      "Qty",
      "Dispensed Quantity",
      "Dispense Qty",
      "Qty_Dispensed",
      "Package Quantity",
    ],
    wholesaler_invoice_number: [
      "Wholesaler Invoice Number",
      "Wholesaler PO",
      "Invoice Number",
      "Wholesaler_invoice_number",
      "Wholesaler Invoice #",
      "Invoice #",
      "replenished order number",
    ],
    prescriber_id_qualifier: [
      "Prescriber ID Qualifier",
      "prescriber_id_qualifier",
      "Prescriber Qualifier",
      "Provider ID type",
    ],
    prescriber_id: [
      "Prescriber ID",
      "prescriber npi",
      "Prescriber DEA",
      "prescriber_id",
      "PrescriberNPIorDEA",
      "Prescriber NPI Or Dea",
    ],
    service_provider_id_qualifier: [
      "Service Provider ID Qualifier",
      "Provider ID Qualifier",
      "service_provider_id_qualifier",
      "Pharmacy Qualifier",
      "Service Provider ID Qualifier NCPDP_NABP",
      "Service provider ID type",
      "Pharmacy NPI Qualifier",
    ],
    service_provider_id: [
      "Service Provider ID",
      "service_provider_id",
      "Service Provider ID NPI",
      "Pharmacy #",
      "Service Provider ID NCPDP_NABP",
      "Pharmacy DEA",
      "Ph NCPDP",
      "Pharmacy NCPDP",
      "Pharmacy NPI",
      "Prescriber NPI",
      "facility_opa_340b_id",
      "Store NPI",
      "Pharmacy",
      "NPI",
    ],
    contracted_entity_id: [
      "Contracted Entity ID",
      "Contracted Entity",
      "OPA Code",
      "HRSA #",
      "Entity ID",
      "contracted_entity_id",
      "CE ID",
      "340B ID",
      "CONTRACTED_ENTITY_ID",
    ],
    claim_conforms_flag: ["claim_conforms_flag"],
  };

  static isValidColumnMapping(column, mappingName, notRequired) {
    var getColumn = this.columnNames[column];

    if (notRequired?.includes(column) || !getColumn) {
      return true;
    }

    if (!mappingName) {
      return false;
    }

    var cleanNames = _.map(getColumn, (name) => name.toLowerCase().replace(/\s/g, ""));
    var cleanMappingName = mappingName.toLowerCase().replace(/\s/g, "");

    if (cleanNames.includes(cleanMappingName)) {
      return true;
    } else {
      return false;
    }
  }
}
