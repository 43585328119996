import React, { useState } from "react";
import Billboard from "./components/Billboard";
import Form from "~/components/ExceptionRequest/components/Form";
import Notice from "~/components/shared/Notice";

const ExceptionRequest = ({ uuid }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <div className="form__registration animated fadeInUp--sm">
          <Form uuid={uuid} />
        </div>
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ExceptionRequest;
