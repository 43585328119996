import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Establish Your Beacon Account</div>
      <p>
        Your registration has been approved! Please set a password for your Beacon account. Use a strong and unique
        password to keep your Beacon account secure. As an additional measure of security, after setting your password
        you will be prompted to establish a multi-factor authentication (MFA) method.
      </p>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
