import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Success!</div>
      <p>
        Beacon has received your registration materials and will begin the review process. Please allow up to 5 business
        days for registration processing. Once your registration has been validated, you will receive an email with
        information on how to establish your account credentials to log in to Beacon. If you still need to provide
        documentation on any remaining selected covered entities, you can navigate back to the enrollment page and click
        Continue Registration to provide outstanding documentation.
      </p>
      <p>
        Once your account has been approved, please be prepared to verify your covered entity's banking details by
        providing the account number, routing number, and a Certified Bank Letter from your covered entity's financial
        institution.
      </p>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
