import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function StatementsTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex}) {

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor("kind", {
      header: "Type",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
    }),
    columnHelper.accessor("credit_id", {
      header: "Credit ID",
      cell: (info) => info.getValue(),
      filterFn: "equalsString",
      sorting: false,
    }),
    columnHelper.accessor("transaction_id", {
      header: "Transaction ID",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("ndc", {
        header: "NDC",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("service_provider", {
        header: "Service Provider",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("date", {
        header: "Date",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("amount", {
        header: "Amount",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
  ];
});

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default StatementsTable;