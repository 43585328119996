import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { UserModal } from "./modal/user_modal";

const UserDialog = ({ initials, name, email, first_name, last_name, mode, method }) => {
  const {
    control,
    register,
    watch,
    formState: { touchedFields, errors, isValid },
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const [open, setOpen] = useState(false);
  const filterRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [selectedMode, setSelectedMode] = useState(mode);

  // const modes = [
  //   {
  //     label: "Sandbox",
  //     value: "SANDBOX",
  //   },
  //   {
  //     label: "Production",
  //     value: "PRODUCTION",
  //   },
  // ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const renderSelectedIndicator = (mode) => {
    if (mode.value == selectedMode) {
      return <div className="indicator__selected"></div>;
    }
  };

  const renderDialog = () => {
    if (open) {
      return (
        <div className="dialog__container dialog--right">
          <div className="dialog__header">
            <div className="avatar avatar--sm avatar--no-hover">{initials}</div>
            <div className="t__stack">
              <div className="t__stack__title">{name}</div>
              <div className="t__stack__subtitle">{email}</div>
            </div>
          </div>

          <div className="dialog__item" onClick={() => setOpenModal(!openModal)}>
            <span className="material-symbols-outlined">settings</span>
            Settings
          </div>
          <UserModal
            register={register}
            method={method}
            openModal={openModal}
            setOpenModal={setOpenModal}
            errors={errors}
            initials={initials}
            name={name}
            email={email}
            first_name={first_name}
            last_name={last_name}
          />

          <a href="/logout">
            <div className="dialog__item">
              <span className="material-symbols-outlined">logout</span>
              Sign Out
            </div>
          </a>
        </div>
      );
    }
  };

  return (
    <div ref={filterRef} style={{ position: "relative" }}>
      <div
        className="avatar"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {initials}
      </div>
      {renderDialog()}
    </div>
  );
};

export default UserDialog;
