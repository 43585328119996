var descriptions = {
  date_of_service: {
    title: "Date of Service",
    description: "Date on which the patient filled their prescription.",
    kind: "error",
    display: false,
  },
  date_prescribed: {
    title: "Date Prescribed",
    name: "date_prescribed",
    description: "Date the prescriber wrote the prescription.",
    kind: "error",
    display: false,
  },
  rx_number: {
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    kind: "error",
    display: false,
  },
  ndc: {
    title: "NDC",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    kind: "error",
    display: false,
  },
  quantity: {
    title: "Quantity",
    description: "The number of units in the prescription.",
    kind: "error",
    display: false,
  },
  service_provider_id: {
    title: "Service Provider ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    kind: "error",
    display: false,
  },
  contracted_entity_id: {
    title: "Contracted Entity ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    kind: "error",
    display: false,
  },
  prescriber_id: {
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    kind: "warning",
    display: false,
  },
  prescriber_id_qualifier: {
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    kind: "warning",
    display: false,
  },
  service_provider_id_qualifier: {
    title: "Service Provider ID Qualifier",
    description:
      "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    kind: "warning",
    display: false,
  },
  wholesaler_invoice_number: {
    title: "Wholesaler Invoice Number",
    description:
      "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
    kind: "warning",
    display: false,
  },
};

export const validationDescriptions = () => {
  return descriptions;
};
