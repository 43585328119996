import React from "react";
import noTasks from "~/images/empty.png";

const EmptyTablePlaceHolder = ({ noColumnMapping, noFilterMatches, setSelectedTab }) => {
  const renderMessage = () => {
    if (noColumnMapping) {
      return (
        <div className="data-table__message__content">
          <div className="data-table__message__content__title">Missing Column Mapping</div>
          <div className="data-table__message__content__subtitle">
            It looks like you have not made a column mapping.
            <br />
            Please create a{" "}
            <span
              style={{ fontWeight: 500, textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setSelectedTab("mappings")}
            >
              Column Mapping{" "}
            </span>
            to complete your submission.
          </div>
        </div>
      );
    }
    if (noFilterMatches) {
      return (
        <div className="data-table__message__content">
          <div className="data-table__message__content__title">No Matching NDCs</div>
          <div className="data-table__message__content__subtitle">
            It looks like there are no matching NDCs in this file.
            <br />
            Please confirm that your
            <span style={{ fontWeight: 500, textDecoration: "underline" }}>Column Mappings</span> are correct.
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="data-table__message">
      <div className="data-table__message__image">
        <img src={noTasks} />
      </div>
      {renderMessage()}
    </div>
  );
};

export default EmptyTablePlaceHolder;
