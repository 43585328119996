import React from "react";
import PrimaryModal from "./library/primary_modal";

const ConfirmationModal = (props) => {
  const modalBody = () => {
    return <div className="t--md t--gray pt-3 pb-3">{props.body}</div>;
  };

  const buttonLabel = () => {
    if (props?.isDeleting) {
      return (
        <div className="loading-indicator loading-indicator--no-margin"></div>
      );
    }

    return props.primaryLabel;
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end" style={{ gap: 20 }}>
        <button
          className="btn btn--light-outline btn--small"
          style={{ lineHeight: "inherit" }}
          onClick={props.secondaryAction}
          disabled={props.isDeleting}
        >
          {props.secondaryLabel}
        </button>
        <button
          className="btn btn--small"
          onClick={props.primaryAction}
          disabled={props.isDeleting}
        >
          {buttonLabel()}
        </button>
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <PrimaryModal
        title={props.title}
        footer={modalFooter()}
        body={modalBody()}
        width="400px"
        showCloseIcon={true}
        setDisplayModal={props.setDisplayModal}
      />
    );
  }

  return null;
};

export default ConfirmationModal;
