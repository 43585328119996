import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useForm } from "react-hook-form";
import httpClient from "/components/utils/httpClient";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const ContinueForm = ({ setNotice }) => {
  const [email, setEmail] = useState("");
  const {
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const handleSendEnrollmentId = (e) => {
    e.preventDefault();

    httpClient
      .post("/prospects/send_enrollment_id", { email })
      .then((res) => {
        setNotice({
          kind: "success",
          open: true,
          message: res.data.notice,
        });
      })
      .catch(() => {
        setNotice({
          kind: "error",
          open: true,
          message: "Please enter a valid email address",
        });
      });
  };

  return (
    <form method="get" action="/prospects/edit">
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector("[name=csrf-token]").content}
      />
      <div className="form__section">Registration Details</div>
      <div className="form__group">
        <label className="form__label">Email</label>
        <CustomTextField
          placeholder="Work Email Address"
          size="small"
          fullWidth={true}
          onInput={(e) => setEmail(e.target.value)}
          variant="outlined"
          {...register("prospect[email]", {
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          error={errors.prospect ? _.has(errors.prospect, "email") : false}
        />
        {errors.prospect?.email && (
          <div className="form__error--message">{errors.prospect?.email.message}</div>
        )}
      </div>

      <div className="form__group">
        <label className="form__label">Enrollment ID</label>
        <CustomTextField
          placeholder="********"
          size="small"
          fullWidth={true}
          variant="outlined"
          {...register("prospect[enrollment_id]", {
            required: "Field is required",
          })}
          error={errors.prospect ? _.has(errors.prospect, "enrollment_id") : false}
        />
        {errors.prospect?.enrollment_id && (
          <div className="form__error--message">{errors.prospect?.enrollment_id.message}</div>
        )}
        <div
          className={`mt--1 ${_.isEmpty(email) ? "t--link" : "t--link"}`}
          onClick={handleSendEnrollmentId}
        >
          Resend Enrollment ID to Email
        </div>
      </div>

      <div className="form__group">
        <button
          type="submit"
          className={"btn btn--fw " + (isValid ? "" : "btn--disabled")}
          disabled={!isValid}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default ContinueForm;
