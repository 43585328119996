import React from "react";
import Form from "./form";
import Billboard from "./billboard";

const Entities = ({ prospect_id, registrations }) => {
  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <Form prospect_id={prospect_id} registrations={registrations} />
      </div>
    </div>
  );
};

export default Entities;
