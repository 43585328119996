import React, { useContext } from "react";
import { AccordionContext } from "/components/shared/accordion/accordion";

// Used inside Accordion Context
// Must take matching id with Accordion Header

const AccordionPanel = ({ children, id }) => {
  const { openAccordion } = useContext(AccordionContext);
  const isOpen = id === openAccordion;

  if (isOpen) {
    return <div className="accordion-panel accordion-panel-open">{children}</div>;
  }

  return <div className="accordion-panel accordion-panel-close">{children}</div>;
};

export default AccordionPanel;
