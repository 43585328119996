import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function PackagesTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex}) {

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor("invoice_number", {
      header: "Invoice",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
    }),
    columnHelper.accessor("package_number", {
      header: "Package",
      cell: (info) => info.getValue(),
      filterFn: "equalsString",
      sorting: false,
    }),
    columnHelper.accessor("units", {
      header: "Units",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
  ];
});

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        minRows={0}
      />
    </div>
  );
}

export default PackagesTable;
