export const isGrantee = (entity_type_code) => {
  if (!entity_type_code) {
    return false;
  }

  if (!["RRC", "SCH", "CAH", "DSH", "PED", "CAN"].includes(entity_type_code)) {
    return true;
  }

  return false;
};

export const isLimitedGrantee = (entity_type_code) => {
  if (!entity_type_code) {
    return false;
  }

  if (!["RRC", "SCH", "CAH", "DSH", "PED", "CAN", "CH", "CHC"].includes(entity_type_code)) {
    return true;
  }

  return false;
};

export const isLimitedHospitalAndGrantee = (entity_type_code) => {
  if (!entity_type_code) {
    return false;
  }

  if (!["RRC", "SCH", "CAH", "DSH", "CH", "CHC"].includes(entity_type_code)) {
    return true;
  }

  return false;
};
