import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Register Your Covered Entity(s)</div>
      <p>
        As the account administrator, you must provide the requested documentation for each covered entity included in
        your registration. You will be able to revisit registration should this documentation not be immediately
        available. You can continue your registration by navigating to the Continue Registration page and entering your
        Enrollment ID.
      </p>
      <div className="mt--2 t--bold">Steps to Register</div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">1</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Select your Covered Entity(s)</div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">2</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Provide the Employer Identification Number (EIN)</div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">3</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">
            Upload the IRS letter stating the Employer Identification Number (CP 575)
          </div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">4</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Upload the Articles of Incorporation</div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">5</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Submit</div>
        </div>
      </div>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
