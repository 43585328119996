import React, { useEffect, useRef, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DropdownSelectMapping from "./dropdown_select_mapping";
import { DragIndicator, DeleteOutlined } from "@mui/icons-material";
import ConfirmModal from "/components/modals/ConfirmModal";

const AttachedFile = ({
  pos,
  file,
  attachment,
  selected,
  usedFields,
  handleRemoveFile,
  mappingTabSelected,
  mappings,
  handleSelectMapping,
  handleSelectAttachment,
}) => {
  const [displayFileHeaders, setDisplayFileHeaders] = useState(false);
  const fileNameRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const [displayDiscardChangesModal, setDisplayDiscardChangesModal] = useState(false);

  useEffect(() => {
    const isTextClipped = (element) => {
      return element.scrollWidth > element.clientWidth;
    };
    if (fileNameRef.current) {
      setShowTooltip(isTextClipped(fileNameRef.current));
    }
  }, []);

  useEffect(() => {
    if (mappingTabSelected && selected) {
      setDisplayFileHeaders(true);
    } else {
      setDisplayFileHeaders(false);
    }
  }, [mappingTabSelected, selected]);

  const toggleDisplayFileHeaders = () => {
    if (mappingTabSelected) {
      return;
    } else {
      setDisplayFileHeaders(!displayFileHeaders);
    }
  };

  const renderTooltip = () => {
    if (fileNameRef.current.scrollWidth > fileNameRef.current.clientWidth) {
      return <div className="tt__container">{attachment.name}</div>;
    }
  };

  const renderAttachmentHighlight = () => {
    if (selected && attachment.hasErrors) {
      return "attachments__tile attachments__tile--space attachments__tile--selected--errors";
    } else if (selected) {
      return "attachments__tile attachments__tile--space attachments__tile--selected";
    } else {
      return "attachments__tile attachments__tile--space";
    }
  };

  const renderAttachmentStatus = () => {
    if (attachment.hasErrors) {
      return <div className="attachments__tile__status-tag attachments__tile__status-tag--errors">Errors</div>;
    } else {
      return <div className="attachments__tile__status-tag">Good</div>;
    }
  };

  const renderfileHeaders = () => {
    if (displayFileHeaders && selected) {
      const orderedfileHeaders = [...attachment.originalColumnNames]
        .sort((a, b) => a.localeCompare(b))
        .filter((item) => item !== "beacon_id");

      return (
        <div>
          <div className="t--sm t--500 mt--16">File Column Headers</div>
          <Droppable droppableId="attachedFile">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className="attachments__info__container">
                {orderedfileHeaders.map((title, i) => (
                  <Draggable
                    isDragDisabled={usedFields.includes(title)}
                    key={`${file.id}#${title}`}
                    draggableId={`${file.id}#${title}`}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`list__item flex--start ${usedFields.includes(title) ? "list__item--disabled" : ""}`}
                      >
                        <span className="material-symbols-outlined">drag_indicator</span>
                        <div className="t--tny">{title}</div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      );
    }
  };

  const removeFile = (e) => {
    e.stopPropagation();
    handleRemoveFile(attachment.id, file.id);
    setDisplayDiscardChangesModal(false);
  };

  return (
    <>
      <div key={pos} className={renderAttachmentHighlight(pos)} onClick={() => handleSelectAttachment(attachment.id)}>
        {renderAttachmentStatus()}
        <div className="attachments__tile__header__container">
          <div className="container">
            <div className="attachments__tile__title__container">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.339" height="33.63" viewBox="0 0 26.339 33.63">
                <g transform="translate(-0.223)">
                  <path
                    d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
                    transform="translate(2.541)"
                    fill="#def8ea"
                  />
                  <path
                    d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z"
                    transform="translate(19.089)"
                    fill="#2bd67b"
                  />
                  <g transform="translate(0 14.401)">
                    <rect width="23" height="10" rx="2" transform="translate(0.223 0.344)" fill="#24b668" />
                    <text
                      transform="translate(6.223 7.344)"
                      fill="#fff"
                      fontSize="6"
                      fontFamily="Roboto-Bold, Roboto"
                      fontWeight="700"
                      letterSpacing="0.007em"
                    >
                      <tspan x="0" y="0">
                        XLS
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
              <div className="attachments__tile__details__filename" ref={fileNameRef}>
                {attachment.name}
              </div>
              {/* {showTooltip && (
              <div
                className="tt tt--width-unset t--sm"
                style={{
                  top: 40,
                  right: -40,
                  width: 276,
                  wordWrap: "break-word",
                }}
              >
                {attachment.name}
              </div>
            )} */}
            </div>
          </div>
        </div>
        <div className="attachments__tile__details--block">
          <div style={{ margin: "20px 0px" }}>
            <div className="flex">
              <div className="attachments__tile__details__mapping__name">Column Mapping</div>
            </div>
            <DropdownSelectMapping
              fileId={file.id}
              submissionId={attachment.id}
              mappings={mappings}
              selectedMapping={attachment.mapping}
              handleSelectMapping={handleSelectMapping}
            />
          </div>
        </div>
        <div className="container container--gap-sm">
          <button className="button button--outlined button--fw" onClick={() => setDisplayDiscardChangesModal(true)}>
            <span className="material-symbols-outlined">delete</span>
            remove
          </button>
          <button
            className="button button--outlined button--fw"
            onClick={() => setDisplayFileHeaders(!displayFileHeaders)}
          >
            {displayFileHeaders ? "Hide Details" : "Show Details"}
          </button>
        </div>
        {renderfileHeaders()}
      </div>
      <ConfirmModal
        displayModal={displayDiscardChangesModal}
        setDisplayModal={setDisplayDiscardChangesModal}
        title="Discard changes?"
        primaryActionText="Yes, Discard Changes"
        body="If you proceed, you will lose all of the changes you have made. This cannot be undone."
        primaryAction={removeFile}
        secondaryAction={() => setDisplayDiscardChangesModal(false)}
      />
    </>
  );
};

export default AttachedFile;
