import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function QuantityHistoryTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex}) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();


    return [
      columnHelper.accessor("submission_name", {
        header: "Submission",
        sorting: false,
        cell: (info) => <div className={info.row.original.discarded ? "t--strikethrough t--grey t--wordwrap" : "t--wordwrap"}>{info.getValue()}</div>,
      }),
      columnHelper.accessor("quantity", {
        header: "Quantity",
        cell: (info) => <div className={info.row.original.discarded ? "t--strikethrough t--grey" : ""}>{info.getValue()}</div>,
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("balance", {
        header: "Balance",
        cell: (info) => <div className={info.row.original.discarded ? "t--strikethrough t--grey" : ""}>{info.getValue()}</div>,
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });


  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        minRows={0}
        disablePagination
      />
    </div>
  );
}

export default QuantityHistoryTable;
