import _ from "lodash";

export const search = (pharmacies, searchTerms) => {
  const keys = ["address", "pharmacy_name"];

  return pharmacies.filter((pharmacy) => {
    return keys.some((key) => pharmacy[key].toLowerCase().includes(searchTerms.searchQuery.toLowerCase()));
  });
};

export const filterByStatus = (pharmacies, searchTerms, eligibility, statuses) => {
  return search(pharmacies, searchTerms).filter((pharmacy) => {
    var items = _.chain(eligibility[pharmacy.pharmacy_id]).pick(statuses).values().uniq().value();

    return _.some(searchTerms.filterQuery, (query) => items.includes(query));
  });
};
