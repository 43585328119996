import React, { useState } from "react";
import DeleteButton from "~/components/shared/DeleteButton";

const FileUpload = ({ registration, id, fileName, required = false, accept = "" }) => {
  const [file, setFile] = useState();

  const handleFile = (e) => {
    setFile({ name: e.target.files[0].name, type: e.target.files[0].type });
  };

  const deleteFile = (e) => {
    document.getElementById(id).value = "";
    setFile(undefined);
  };

  const renderFile = () => {
    if (file) {
      return (
        <>
          <div className="file_upload__icon">
            <DeleteButton onClick={deleteFile} />
          </div>
          <div className="file_upload__details">
            <div className="file_upload__details__title">{file?.name}</div>
            <div className="file_upload__details__subtitle">{file?.type}</div>
          </div>
        </>
      );
    }

    return (
      <div className="file_upload__note">
        Please select a file to upload {required ? "- this field is required" : ""}
      </div>
    );
  };

  return (
    <div className="file_upload">
      {renderFile()}
      <button
        type="button"
        className="btn btn--outline"
        onClick={() => document.getElementById(id).click()}
      >
        <span className="material-symbols-outlined">post_add</span>
        Select File
      </button>
      <input
        type="file"
        name={`registrations[attrs][${registration.id}][${fileName}]`}
        id={id}
        required={required}
        style={{ display: "none" }}
        onChange={handleFile}
        accept={accept}
      />
    </div>
  );
};

export default FileUpload;
