import React from "react";
import { Skeleton } from "@mui/material";

const TableLoadingSkeleton = ({ columns, rows, fadeIn }) => {
  return [...Array(rows)].map((_, i) => (
    <tr className={`hoverless-tr ${fadeIn ? "animated delay-2 fadeIn" : ""}`} key={i}>
      {[...Array(columns)].map((_, i) => (
        <td key={i}>
          <Skeleton animation="wave" />
        </td>
      ))}
    </tr>
  ));
};

export default TableLoadingSkeleton;
