import React from "react";

const BillboardSupportFooter = () => {
  return (
    <>
      <div className="mt--4 t--light">Questions?</div>
      <p className="t--italic t--subtitle">
        Click the icon in the lower right to chat live with the Beacon Support Center.
      </p>
      <div className="mt--2 t--light">Prefer to email?</div>
      <p className="t--italic t--subtitle">
        <a href="mailto:support@beaconchannelmanagement.com">support@beaconchannelmanagement.com</a>
      </p>
      <div className="footer__logo__container mt--4">
        <div className="footer__logo" />
        <div className="footer__logo__name">Beacon Channel Management</div>
        <div className="footer__link__separator" />
        <div className="footer__link">
          <a href="/pages/terms">Terms</a>
        </div>
        <div className="footer__link__separator" />
        <div className="footer__link">
          <a href="/pages/privacy">Privacy</a>
        </div>
      </div>
    </>
  );
};

export default BillboardSupportFooter;
