import React from "react";

const ProgressBar = ({
  progress,
  total,
  width = "auto",
  height = "auto",
  label = undefined,
}) => {
  const progressPercentage = (progress / total) * 100;

  return (
    <>
      {label && label}
      <div
        className="progress"
        style={{ width: width.toString(), height: height.toString() }}
      >
        <div
          className="progress progress__bar"
          style={{ width: progressPercentage + "%" }}
        ></div>
      </div>
    </>
  );
};

export default ProgressBar;
