const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "0 0 0 0",
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 138,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 12,
    fontStyle: "italic",
    color: "#718291",
  }),
  control: (base) => ({
    ...base,
    minHeight: 36,
    borderColor: "#718291",
  }),
};

export const customSelect = customSelectStyles;
