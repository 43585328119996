import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const FormSelect = ({
  name,
  label,
  placeholder,
  containerClass = "",
  control,
  required = true,
  errors = {},
  options
}) => {

  const errorForName = name.split(".").reduce((o, i) => o ? o[i] : null, errors);

  return (
    <div className={`form__group ${containerClass}`}>
      <label className="form__label">{label}</label>
      <Controller
        control={control}
        name={name}
        rules={required ? { required: 'This field is required.' } : undefined}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            placeholder={placeholder}
            options={options}
            required={required}
            inputRef={ref}
            onChange={(val) => onChange(val.value)}
            value={value && options.find((o) => o.value === value)}
            aria-invalid={errorForName}
            name={name}
          />
        )}
      />
      {errors && errorForName && (
        <div className="form__error--message">{errorForName["message"]}</div>
      )}
    </div>
  );
};

export default FormSelect;
