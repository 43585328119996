import React from "react";

const BankAccountNotice = ({ status }) => {
  if (status === "in_review" || status === "bank_info_approved") {
    return (
      <>
        <div className="bank_details__container__header">
          <div className="indicator indicator--orange indicator--pulse"></div>
          <div className="bank_card__title">Bank Details In Review</div>
        </div>
        <div className="t--light t--subtitle t--sm">
          Your provided banking details are currently under review. Beacon Support will provide a
          status update once the review is complete.
        </div>
      </>
    );
  }

  if (status === "unverified") {
    return (
      <>
        <div className="bank_details__container__header">
          <div className="indicator indicator--orange indicator--pulse"></div>
          <div className="bank_card__title">Pending Verification</div>
        </div>
        <div className="t--light t--subtitle t--sm">
          Your provided bank details are currently pending further verification.
        </div>
      </>
    );
  }

  if (status === "missing") {
    return (
      <>
        <div className="bank_details__container__header">
          <div className="indicator indicator--orange indicator--pulse"></div>
          <div className="bank_card__title">Missing Bank Details</div>
        </div>
        <div className="t--light t--subtitle t--sm">
          To receive credit payments, you must successfully submit and verify your bank account
          details. Click the button below to provide your bank account information.
        </div>
      </>
    );
  }

  if (status === "active") {
    return (
      <>
        <div className="bank_details__container__header">
          <div className="indicator indicator--green"></div>
          <div className="bank_card__title">Account Active</div>
        </div>
        <div className="t--light t--subtitle t--sm">
          You are able to receive credit payments. If you require any changes to your bank details,
          please reach out to support.
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bank_details__container__header">
        <div className="indicator indicator--red indicator--pulse"></div>
        <div className="bank_card__title">Contact Support</div>
      </div>
      <div className="t--light t--subtitle t--sm">
        There is an issue with your account. Please reach out to support through the chat in the
        bottom right corner of the page or by email.
      </div>
    </>
  );
};

export default BankAccountNotice;
