import React, { useEffect } from "react";
import _ from "lodash";
import { CustomTextField } from "./form";

const ContactDetails = ({ register, errors, setError, watch, clearErrors, touchedFields }) => {
  const watchedEmail = watch("prospect[email]");
  const watchedEmailConfirmation = watch("prospect[confirm_email]");
  useEffect(() => {
    if (watchedEmail?.toLowerCase() !== watchedEmailConfirmation?.toLowerCase()) {
      touchedFields.prospect?.email && setError("prospect[email]", { message: "Emails do not match" })
      touchedFields.prospect?.confirm_email && setError("prospect[confirm_email]", { message: "Emails do not match" })
    } else {
      clearErrors("prospect[email]");
      clearErrors("prospect[confirm_email]");
    }
  }, [watchedEmail, watchedEmailConfirmation]);

  return (
    <>
      <div className="form__section">Contact Details</div>
      <div className="form__group__multi">
        <div className="form__group">
          <label className="form__label">First Name</label>
          <CustomTextField
            placeholder="John"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("prospect[first_name]", {
              required: "Field is required",
            })}
            error={errors.prospect ? _.has(errors.prospect, "first_name") : false}
          />
          {errors.prospect?.first_name && (
            <div className="form__error--message">{errors.prospect?.first_name.message}</div>
          )}
        </div>
        <div className="form__group">
          <label className="form__label">Last Name</label>
          <CustomTextField
            placeholder="Doe"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("prospect[last_name]", {
              required: "Field is required",
            })}
            error={errors.prospect ? _.has(errors.prospect, "last_name") : false}
          />
          {errors.prospect?.last_name && (
            <div className="form__error--message">{errors.prospect?.last_name.message}</div>
          )}
        </div>
      </div>
      <div className="form__group">
        <label className="form__label">Email</label>
        <CustomTextField
          placeholder="Work Email Address"
          size="small"
          fullWidth={true}
          variant="outlined"
          {...register("prospect[email]", {
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
            validate: {
              emailEqual: (value) => errors.prospect && _.has(errors.prospect, "email"),
            },
          })}
          error={errors.prospect ? _.has(errors.prospect, "email") : false}
        />
        {errors.prospect?.email && (
          <div className="form__error--message">{errors.prospect?.email.message}</div>
        )}
        <div className="form__group__note mt-2">
          Please provide the email address associated with your covered entity(s).
        </div>
      </div>
      <div className="form__group">
        <label className="form__label">Confirm Email</label>
        <CustomTextField
          placeholder="Confirm Email Address"
          size="small"
          fullWidth={true}
          variant="outlined"
          {...register("prospect[confirm_email]", {
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
            validate: {
              emailEqual: (value) => errors.prospect && _.has(errors.prospect, "confirm_email"),
            },
          })}
          error={errors.prospect ? _.has(errors.prospect, "confirm_email") : false}
        />
        {errors.prospect?.confirm_email && (
          <div className="form__error--message">{errors.prospect?.confirm_email.message}</div>
        )}
      </div>
    </>
  );
};

export default ContactDetails;
