import _ from "lodash";
import { columnSchema } from "./pharmacy_claim";
export class PharmacySubmission {
  constructor(id, name, mapping) {
    this.id = id;
    this.name = name;
    this.mapping = mapping;
    this.data = [];
    this.errors = false;
    this.warnings = _.reduce(
      columnSchema,
      (obj, _, key) => {
        obj[key] = false;
        return obj;
      },
      {}
    );
    this.column_mapping_warnings = _.reduce(
      columnSchema,
      (obj, _, key) => {
        obj[key] = false;
        return obj;
      },
      {}
    );

    // this.warnings = [];
    /* Should this ^ be an object instead? What is this going to be used for exactly? 
    The only submission-level error I can think of is column mapping warning */
  }
}
