import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Controller } from "react-hook-form";

const FileUpload = ({ id, fileName, control, errors, accept = "" }) => {
  const [file, setFile] = useState();

  const handleFile = (e) => {
    setFile({ name: e.target.files[0].name, type: e.target.files[0].type });
  };

  const renderFile = () => {
    if (file) {
      return (
        <>
          <div className="file_upload__icon">
            <span className="material-symbols-outlined">description</span>
          </div>
          <div className="file_upload__details">
            <div className="file_upload__details__title">{file?.name}</div>
            <div className="file_upload__details__subtitle">{file?.type}</div>
          </div>
        </>
      );
    }

    return <div className="file_upload__note">Please select a file to upload</div>;
  };

  return (
    <div className="file_upload">
      {renderFile()}
      <button
        type="button"
        className="button button--outlined"
        onClick={() => document.getElementById(id).click()}
      >
        <span className="material-symbols-outlined">post_add</span>
        Select File
      </button>
      <Controller
        control={control}
        name={`bank_account[${fileName}]`}
        rules={{ required: "Field is required" }}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <input
              type="file"
              name={`bank_account[${fileName}]`}
              id={id}
              style={{ display: "none" }}
              {...field}
              value={value?.fileName}
              onChange={(e) => {
                handleFile(e);
                onChange(e);
              }}
              accept={accept}
            />
          );
        }}
      />

      {errors.bank_account?.["bank_letter"] && (
        <div className="form__error--message">{errors.bank_account?.["bank_letter"]?.message}</div>
      )}
    </div>
  );
};

export default FileUpload;
