import React, { useState } from "react";
import {useForm} from "react-hook-form";
import FormInput from "~/components/shared/FormInput";
import FormSelect from "~/components/shared/FormSelect";
import FileUpload from "~/components/shared/FileUpload";
import SubmitButton from "~/components/shared/SubmitButton";

const MANUFACTURERS = ["Johnson & Johnson"];

const Form = ({ uuid } ) => {
  const defaultValues = {
    uuid: uuid,
    first_name: "",
    last_name: "",
    email: "",
    manufacturer: "",
    supporting_documents: [],
  };

  const {
    control,
    register,
    formState: { isValid, errors },
  } = useForm({ defaultValues, mode: "onChange" });

  const manufacturerList = MANUFACTURERS.map((manufacturer) => {
    return { label: manufacturer, value: manufacturer }
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  return (
    <form
      method="post"
      encType="multipart/form-data"
      action={`/exception_requests/${uuid}`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="_method" value="patch"/>
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector("[name=csrf-token]").content}
      />
      <div className="form__section">Contact Details</div>
      <div className="form__group__multi">
        <FormInput
          label="First Name"
          placeholder="John"
          name="first_name"
          register={register}
          errors={errors}
          required={true}
        />
        <FormInput
          label="Last Name"
          placeholder="Doe"
          name="last_name"
          register={register}
          errors={errors}
          required={true}
        />
      </div>
      <FormInput
        label="Email"
        placeholder=""
        name="email"
        register={register}
        required={true}
        registerOptions={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        }}
        errors={errors}
      />
      <div className="form__section">Manufacturer</div>
      <FormSelect
        label="Select the manufacturer"
        placeholder="Select..."
        options={manufacturerList}
        required={true}
        control={control}
        errors={errors}
        name="manufacturer"
      />
      <div className="form__section">Documentation</div>
      <FileUpload
        name="supporting_documents[]"
        label="Provide documentation"
        required={true}
        multiple={true}
        control={control}
        errors={errors}
      />
      <div className="form__group">
        <SubmitButton isDisabled={!isValid || isSubmitting} />
      </div>
    </form>
  );
};

export default Form;
