// Currently, these ↓ data validations are too specific to work for medical claim validation. Needs refactor
import {
  isValidDate,
  isValidPlanIDQualifier,
  isValidPlanIDCode,
  isValidPlanName,
  isValidPrescriptionType,
  isValidClaimNumber,
  isValidHCPCS,
  isValidHCPCSCodeModifier,
  isValidContractedEntityID,
  isValidQuantity,
  isValidNPI,
} from "./data_validations";
import { isRequiredField } from "../schemas/medical_claim";

const isValidMedicalClaimField = (value, column, row) => {
  if (["claim_conforms_flag", "beacon_id"].includes(column)) {
    return true;
  }

  if (isRequiredField(column) && !value) {
    return false;
  }

  var cleanValue = value?.trim();

  switch (column) {
    // Medical Columns
    case "date_of_service":
      return isValidDate(cleanValue);
    case "plan_id_qualifier":
      return isValidPlanIDQualifier(cleanValue);
    case "plan_id_code":
      return isValidPlanIDCode(cleanValue);
    case "plan_name":
      return isValidPlanName(cleanValue);
    case "service_provider_id":
      return isValidNPI(cleanValue);
    case "prescriber_id":
      return isValidNPI(cleanValue);
    case "product_service_id":
      return true // skip validations for column we filter by
    case "billed_hcpcs_quantity":
      return isValidQuantity(cleanValue);
    case "prescription_type":
      return isValidPrescriptionType(cleanValue);
    case "claim_number":
      return isValidClaimNumber(cleanValue);
    case "hcpcs_code":
      return isValidHCPCS(cleanValue); 
    case "hcpcs_code_modifier_1":
      return isValidHCPCSCodeModifier(cleanValue);
    case "hcpcs_code_modifier_2":
      return isValidHCPCSCodeModifier(cleanValue);
    case "contracted_entity_id":
      return isValidContractedEntityID(cleanValue);
    default:
      return false;
  }
};

export default isValidMedicalClaimField;
