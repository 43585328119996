import React from "react";

const ColumnMappingNotice = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="notice__banner notice__banner--info notice__banner--m-btm animated fadeInUp">
      Missing Column Mapping - Please create a column mapping to continue
    </div>
  );
};

export default ColumnMappingNotice;
