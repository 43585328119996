import _ from "lodash";

export const FilterData = (data, filterList, filterKey, mapping) => {
  var filtered = _.filter(data, (row) => _.find(filterList, ["code", row[mapping[filterKey]]]));

  return filtered;
};

export const filterRow = (data, filterList, filterKey, mapping) => {
  if (!_.some(filterList, ["code", row[mapping[filterKey]]])) {
    return;
  }
  var filtered = _.filter(data, (row) => _.find(filterList, ["code", row[mapping[filterKey]]]));

  return filtered;
};
