import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInUp--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Sign In To Your Beacon Account</div>
      <p>
        Beacon is modernizing the 340B program by better aligning 340B price effectuation with
        Medicaid, Medicare, and other government programs.
      </p>
      <span className="t--bold" style={{ marginRight: 5 }}>
        New to Beacon?
      </span>
      <a href="/enrollments/registrations">
        <span className="t--link">Create your account</span>
      </a>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
