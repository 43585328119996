import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FileUpload from "/components/entities/components/file_upload";
import FormInput from "/components/shared/FormInput";
import SubmitButton from "~/components/shared/SubmitButton";

const BankDetailsModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (open) {
      reset({
        bank_account: {
          name: null,
          bank_letter: null,
          routing_number: null,
          account_number: null,
          confirm_account_number: null
        }
      });
    }
  }, [open]);

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Provide Bank Account Details</div>
          <div className="t__stack__subtitle">
            Complete the form below and upload the required documentation to establish the bank account information for
            your covered entity. Please note that bank account information must be completed separately for each covered
            entity.
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <FormInput
          label="Account Name"
          placeholder="Name"
          name="bank_account[name]"
          register={register}
          registerOptions={{
            required: "Field is required",
          }}
          errors={errors}
        />
        <FormInput
          label="Routing Number"
          placeholder="110000000"
          name="bank_account[routing_number]"
          register={register}
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^[0-9]{9}$/,
              message: "9 digits required",
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Account Number"
          placeholder="000123456789"
          name="bank_account[account_number]"
          register={register}
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^[0-9]{5,18}$/,
              message: "Only digits allowed; 5-18 characters",
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Confirm Account Number"
          placeholder="000123456789"
          name="bank_account[confirm_account_number]"
          register={register}
          registerOptions={{
            required: "Field is required",
            validate: (val) => {
              if (watch("bank_account[account_number]") != val) {
                return "Account Number does not match";
              }
            },
          }}
          errors={errors}
        />
        <div className="form__group">
          <label className="form__label">Certified Bank Letter</label>
          <p className="form__group__note">
            Please upload a Certified Bank Letter provided by your Financial Institution.
          </p>
          <FileUpload id="bank_letter" name="bank_account[bank_letter]" control={control} accept="image/*,.pdf" />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button type="button" className="button button--label" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <SubmitButton title="Submit" className="button" isDisabled={!isValid || isSubmitting} />
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form
              method="post"
              encType="multipart/form-data"
              action={`/entities/${props.id_340b}/bank_details`}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={document.querySelector("[name=csrf-token]").content}
              />
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="button button--sm button--outlined" onClick={() => setOpen(!open)}>
        Edit Details
      </div>
      {renderModal()}
    </>
  );
};

export default BankDetailsModal;
