import React, { useEffect, useState } from "react";
import BankAccountStatus from "/components/entities/components/BankAccountStatus";
import httpClient from "../utils/httpClient";

const BankDetailsModal = ({ dashboard_path }) => {
  const [open, setOpen] = useState(window.location.pathname == dashboard_path);
  const [entities, setEntities] = useState([]);
  const [fetchedEntities, setFetchedEntities] = useState(false);

  useEffect(() => {
    if (fetchedEntities) return;

    httpClient
      .get("/entities", { params: { needing_action: true } })
      .then((res) => setEntities(res.data))
      .catch((err) => console.log(err))
      .finally(setFetchedEntities(true));
  }, []);

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Bank Account Action Required</div>
          <div className="t__stack__subtitle">
            The following entities require action to complete the bank account setup.
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        {entities.map((entity) => {
          return (
            <a href={entity.path} key={entity.id_340b}>
              <div className="list__item">
                <div className="t__stack">
                  <div className="t__stack__subtitle">{entity.id_340b}</div>
                  <div className="t__stack__title t--sm">{entity.name}</div>
                </div>
                <div className="t__stack" style={{ flexShrink: 0, textAlign: "right", minWidth: "20%" }}>
                  <div className="t__stack__subtitle t--right">Status</div>
                  <BankAccountStatus status={entity.status} />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--outlined" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      );
    }
  };

  if (entities.length === 0) return null;

  return (
    <>
      <div onClick={() => setOpen(!open)} className="alert animated fadeInLeft">
        <div className="indicator indicator--orange indicator--pulse" />
        Bank Account Action Required
      </div>
      {renderModal()}
    </>
  );
};

export default BankDetailsModal;
