import React, { useState, useRef, useEffect, forwardRef } from "react";
import _ from "lodash";

import { Droppable } from "react-beautiful-dnd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CloseOutlined, DeleteOutline as Delete, ErrorOutline as Error } from "@mui/icons-material";
import Validations from "../../validators/mapping_validations";
import ConfirmModal from "../../../modals/ConfirmModal";

const FieldMappingGroup = forwardRef(
  (
    {
      position,
      saveMapping,
      orderedFields,
      inEditMode,
      disabled,
      editState,
      setEditState,
      closeNewMapping,
      removeMapping,
      deleteFieldMappingGroup,
      mapping = {},
      isDeleting,
      notRequired,
      isSaving,
    },
    newMappingRef,
  ) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [displayDiscardChangesModal, setDisplayDiscardChangesModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const missingRequiredFields = orderedFields.some((field) => field.required && !editState.mappings[field.name]);
    const inputRef = useRef();
    const initialMapping = useRef();
    const hasBlankName = !editState.name;
    const saveDisabled = hasBlankName || missingRequiredFields;

    useEffect(() => {
      initialMapping.current = mapping;
    }, []);

    const getClassName = () => {
      if (inEditMode) {
        return "mappings__group--edit-mode";
      }
      if (disabled) {
        return "mappings__group--disabled-mode";
      }

      return null;
    };

    const onDeleteFieldMappingGroup = async () => {
      await deleteFieldMappingGroup(mapping.id);
      setDisplayModal(false);
    };

    const hasActiveChanges = () => {
      return (
        !_.isEqual(editState.mappings, initialMapping.current.mappings) ||
        editState.name !== initialMapping.current.name
      );
    };

    const cancelEditMapping = () => {
      if (hasActiveChanges()) {
        setDisplayDiscardChangesModal(true);
        return;
      } else {
        discardChanges();
      }
    };

    const discardChanges = () => {
      setSaving(false);

      if (closeNewMapping) {
        closeNewMapping();
        return;
      }

      setEditState({ id: null, position: null, name: "", mappings: {} });
      setDisplayDiscardChangesModal(false);
    };

    const editMapping = (mapping) => {
      if (closeNewMapping) {
        closeNewMapping();
        return;
      }

      if (inEditMode) {
        setEditState({ id: null, position: null, name: "", mappings: {} });
        return;
      } else {
        setEditState({
          position: position,
          id: mapping.id,
          name: mapping.name || "",
          mappings: mapping.mappings,
        });
      }
    };

    const handleEditMappingName = (e) => {
      setEditState({ ...editState, name: e.target.value });
    };

    const scrollMissingFieldIntoView = () => {
      if (hasBlankName) {
        inputRef.current.scrollIntoView({ behavior: "smooth" });
        return;
      }

      const missingField = orderedFields.find((field) => field.required && !editState.mappings[field.name]);
      if (missingField) {
        document
          .getElementById(`mapping_group-${mapping.id}-${missingField.title}`)
          .scrollIntoView({ behavior: "smooth" });
        return;
      }
    };

    const handleSave = async () => {
      if (saveDisabled) {
        scrollMissingFieldIntoView();
        setSaving(true);
        return;
      }

      try {
        setSaving(true);
        await saveMapping();
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    };

    const renderActions = (mapping) => {
      if (inEditMode) {
        return (
          <div className="grid" style={{ gap: 20, gridTemplateColumns: "2fr 1fr", marginBottom: 8 }}>
            <div className="flex-col" style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Enter mapping name"
                value={editState.name}
                onChange={handleEditMappingName}
                className={`input t--md t--500 ${saving && hasBlankName ? "input--invalid" : ""}`}
                ref={inputRef}
                disabled={isDeleting || isSaving}
              />
            </div>

            <div className="flex justify-end p-0" style={{ gap: 10 }}>
              <button
                disabled={isDeleting || isSaving}
                className="btn btn--sqr"
                style={{ padding: 5, height: "100%", width: "auto" }}
                onClick={() => cancelEditMapping(mapping)}
              >
                <CloseOutlined />
              </button>
              <div className="tt__container">
                <button onClick={handleSave} className="btn btn--small">
                  {isSaving ? (
                    <div className="loading-state">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="flex" style={{ gap: 20 }}>
          <div className="flex">
            <p className="t--md t--500 p-1 mb-0">{mapping.name}</p>
          </div>

          <div className="flex justify-end p-0" style={{ gap: 10, padding: 10 }}>
            <button
              className={`btn btn--sqr ${disabled ? "btn--disabled" : ""}`}
              style={{ padding: 5, height: "auto", width: "auto" }}
              onClick={() => editMapping(mapping)}
              disabled={disabled}
            >
              <EditOutlinedIcon />
            </button>

            <button
              className={`btn btn--sqr ${disabled ? "btn--disabled" : ""}`}
              onClick={() => {
                setDisplayModal(true);
              }}
              style={{ padding: 5, height: "auto", width: "auto" }}
              disabled={disabled}
            >
              {isDeleting ? (
                <div className="loading-state">
                  <div className="loading"></div>
                </div>
              ) : (
                <DeleteOutlineOutlinedIcon />
              )}
            </button>
          </div>
        </div>
      );
    };

    const renderMappingTag = (id, fieldName, mappingName, canEdit) => {
      if (!mappingName) {
        return (
          <div className="mappings__group__item__assignment__dropzone__contents--empty">Drop your column here</div>
        );
      }

      if (mappingName) {
        return (
          <div key={id} className="mappings__group__item__assignment__dropzone__item flex justify-between">
            <div className="flex">
              <div style={{ fontSize: 10 }}>{mappingName}</div>
            </div>
            {canEdit && (
              <div
                className="mappings__group__item__assignment__dropzone__item__action"
                onClick={() => removeMapping(fieldName)}
                style={{ pointerEvents: isDeleting || isSaving ? "none" : "auto" }}
              >
                <Delete style={{ fontSize: 16 }} />
              </div>
            )}
          </div>
        );
      }
    };

    const renderDropzone = (field) => {
      if (inEditMode) {
        return (
          <Droppable droppableId={`existing#${field.name}#${mapping.id}`}>
            {(provided, snapshot) => {
              return (
                <div
                  ref={provided.innerRef}
                  className="mappings__group__item__assignment__dropzone"
                  style={snapshot.isDraggingOver ? { borderColor: "#3246d3" } : null}
                >
                  {renderMappingTag(mapping.id, field.name, editState.mappings[field.name], inEditMode)}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        );
      }

      return (
        <div className="mappings__group__item__assignment__dropzone">
          {renderMappingTag(mapping.id, field.name, mapping?.mappings?.[field.name], inEditMode)}
        </div>
      );
    };
    const renderWarning = (field) => {
      if (saving && field.required && !editState.mappings[field.name]) {
        return (
          <div className="flex mb--8 animated expand">
            <Error style={{ color: "#f50057", marginRight: 8 }} />
            <div className="t--sm t--500">Required Fields must be mapped</div>
          </div>
        );
      }

      const currentMapping = inEditMode ? editState?.mappings : mapping?.mappings || {};
      if (
        currentMapping?.[field.name] &&
        !Validations.isValidColumnMapping(field.name, currentMapping?.[field.name], notRequired)
      ) {
        return (
          <div className={`flex mb--8 ${inEditMode && "animated expand"}`}>
            <Error style={{ color: "#FF681F", marginRight: 8 }} />
            <div className="t--sm t--500">Check mapping accuracy, or ignore if correct</div>
          </div>
        );
      }
    };

    const renderMappings = () => {
      return orderedFields.map((field) => {
        const getClassName = () => {
          if (saving && field.required && !editState.mappings[field.name]) {
            return "mappings__group__item--error";
          }

          const currentMapping = inEditMode ? editState?.mappings : mapping?.mappings || {};

          if (
            currentMapping?.[field.name] &&
            !Validations.isValidColumnMapping(field.name, currentMapping?.[field.name], notRequired)
          ) {
            return "mappings__group__item--warning";
          }
        };

        return (
          <div
            layout
            key={`mapping_group-${mapping.id}-${field.title}`}
            id={`mapping_group-${mapping.id}-${field.title}`}
            className={`mappings__group__item ${getClassName()}`}
          >
            <div className="mappings__group__item__title-container">
              <div className="mappings__group__item__title">{field.title}</div>
              {field.required ? <div className="mappings__group__item__required-tag">*Required</div> : null}
            </div>
            {renderWarning(field)}
            <div className="mappings__group__item__subtitle">{field.description}</div>
            {renderDropzone(field)}
          </div>
        );
      });
    };

    return (
      <div ref={newMappingRef} className={getClassName() ? `mappings__group ${getClassName()}` : "mappings__group"}>
        {renderActions(mapping)}
        {renderMappings()}

        <ConfirmModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          title="Delete Mapping"
          body="Please confirm you want to delete this mapping. This action cannot be undone"
          primaryAction={onDeleteFieldMappingGroup}
          primaryActionText="Yes, Delete Mapping"
          isDeleting={isDeleting}
          secondaryAction={() => setDisplayModal(false)}
        />

        <ConfirmModal
          displayModal={displayDiscardChangesModal}
          setDisplayModal={setDisplayDiscardChangesModal}
          title="Discard changes?"
          primaryActionText="Yes, Discard Changes"
          body="If you proceed, you will lose all of the changes you have made. This cannot be undone."
          primaryAction={discardChanges}
          secondaryAction={() => setDisplayDiscardChangesModal(false)}
        />
      </div>
    );
  },
);

FieldMappingGroup.displayName = FieldMappingGroup;

export default FieldMappingGroup;
