import React from "react";
import { useForm } from "react-hook-form";
import FormInput from "/components/shared/FormInput";

const Form = () => {
  const {
    register,
    formState: { isValid, errors },
  } = useForm({ mode: "onChange" });

  return (
    <form method="POST" action="/login">
      <div className="form__registration animated fadeInDown--sm">
        <div className="form__section">Sign In to Your Account</div>
        <FormInput
          label="Email"
          name="session[email]"
          errors={errors}
          register={register}
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
        />
        <FormInput
          label="Password"
          name="session[password]"
          type="password"
          errors={errors}
          register={register}
          registerOptions={{
            required: "Field is required",
          }}
        >
          <a href="/password_resets/new">
            <div className="t--link mt--1">Forgot Password?</div>
          </a>
        </FormInput>
        <div className="form__group">
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector("[name=csrf-token]").content}
          />
          <button
            type="submit"
            className={"btn btn--fw " + (isValid ? "" : "btn--disabled")}
            disabled={!isValid}
          >
            Sign In
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
