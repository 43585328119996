import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import RegistrationForm from "../../entities/forms/registration_form";
import FormCheckbox from "/components/shared/FormCheckbox";

const EditEntity = (props) => {
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const selectedEntity = props.id_340b ?? "";

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const handleValidSubmit = (value) => {
    let formData = { ...value };
    formData.registration.id_340b = selectedEntity;

    axios
      .put("/entities/registration_details/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
      })
      .then(() => setOpen(false))
      .catch((err) => {
        setApiError(err.response?.data?.message || err.message);
      });
  };

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Edit Your Covered Entity</div>
          <div className="t__stack__subtitle">
            As the account administrator, you must provide the requested documentation for your Covered Entity
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    if (selectedEntity) {
      return <RegistrationForm selectedEntity={selectedEntity} control={control} errors={errors} />;
    }
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <div className="form__group">
          <div className="t--title">{props.entity_name}</div>
          <div className="t--subtitle">{props.id_340b}</div>
        </div>

        <p className="form__group__note">
          By checking the box below you confirm your authorization to register an account with Beacon for the covered
          entity(s) selected above.
        </p>
        <FormCheckbox
          name="registration.signatory"
          label="I am authorized"
          control={control}
          required={true}
          errors={errors}
        />
        <p className="form__group__note">
          <span>By checking the box below you confirm your understanding of and agree to the </span>
          <span className="t--link">
            <a href="/pages/terms" target="_blank">Terms of Use</a>
          </span>
        </p>
        <FormCheckbox
          name="registration.terms"
          label="Accept Terms & Privacy Policy"
          control={control}
          required={true}
          errors={errors}
        />
        {renderForm()}
        {apiError && <div className="form__error--message">{apiError}</div>}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--label" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <button className={`button ${isValid ? "" : "btn--disabled"}`} action="submit" disabled={!isValid}>
          Save Account Details
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form method="post" onSubmit={handleSubmit(handleValidSubmit)}>
              <input type="hidden" name="registration[id_340b]" value={props.id_340b} />
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`list__item ${
          props.enrollment_status == "Incomplete" || props.enrollment_status == "Failed"
            ? ""
            : "list__item--disable-hover"
        }`}
        onClick={() =>
          setOpen((!open && props.enrollment_status == "Incomplete") || props.enrollment_status == "Failed")
        }
      >
        <div className="t__stack">
          <div className="t__stack__subtitle">Entity ID</div>
          <div className="t__stack__title t--sm"> {props.id_340b} </div>
        </div>
        <div className="t__stack">
          <div className="t__stack__subtitle t--right">Status</div>
          <div
            className={`t__stack__title t--sm t--right ${
              props.enrollment_status == "Incomplete" || props.enrollment_status == "Failed"
                ? "t--orange"
                : props.enrollment_status == "Active"
                ? "t--blue"
                : ""
            }`}
          >
            {props.enrollment_status}
          </div>
        </div>
      </div>

      {renderModal()}
    </>
  );
};

export default EditEntity;
