import { sortedColumnDetails as pharmacyColumnDetails, tableHeaders as pharmacyTableHeaders } from "./pharmacy_claim";
import { sortedColumnDetails as medicalColumnDetails, tableHeaders as medicalTableHeaders } from "./medical_claim";
import { sortedColumnDetails as invoiceColumnDetails, tableHeaders as invoiceTableHeaders } from "./invoice";

export const columnDetails = (kind, encounter = false) => {
  switch (kind) {
    case "pharmacy":
      return pharmacyColumnDetails;
    case "medical":
      return medicalColumnDetails;
    case "invoice":
      return invoiceColumnDetails;
    default:
      return [];
  }
};

export const tableHeaders = (kind, encounter = false) => {
  switch (kind) {
    case "pharmacy":
      return pharmacyTableHeaders;
    case "medical":
      return medicalTableHeaders;
    case "invoice":
      return invoiceTableHeaders;
    default:
      return [];
  }
}