import React from "react";

const GridDetailSection = ({ title, value }) => {
  return (
    <div className="grid__details__section__info__item">
      <div className="grid__details__section__info__item__title">{title}</div>
      <div className="grid__details__section__info__item__value">{value}</div>
    </div>
  );
};

export default GridDetailSection;
