import React, { useContext } from "react";
import { AccordionContext } from "/components/shared/accordion/accordion";

// Used inside Accordion Context
// Must take matching id with Accordion Panel

const AccordionHeader = ({ children, key, id }) => {
  const { openAccordion, setOpenAccordion } = useContext(AccordionContext);
  const isOpen = id === openAccordion;

  const handleAccordion = () => {
    if (id === openAccordion) {
      setOpenAccordion(undefined);
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <div className="accordion" onClick={handleAccordion} key={key}>
      <div className="accordion__header">
        {children}
        <span className="material-symbols-outlined" style={{ alignContent: "center" }}>
          {isOpen ? "expand_less" : "expand_more"}
        </span>
      </div>
    </div>
  );
};

export default AccordionHeader;
