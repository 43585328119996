import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import CustomPopover from "/components/shared/popover";
import Table from "/components/shared/table";

function MedicalClaimsTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  customerFilter,
  statusFilter,
  setNotice,
  handleRowClick,
  selectedRowIndex,
  selectedPage,
  getIndicatorColor,
}) {
  const filterSetup = useMemo(
    () => [
      {
        columnName: "customer_name",
        filterValue: customerFilter,
      },
      {
        columnName: "upload_status",
        filterValue: statusFilter,
      },
    ],
    [customerFilter, statusFilter],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    const renderIdPopoverContent = (value) => {
      return (
        <div className="content p--5 flex popover__copy">
          <span className="t--tny">{value}</span>
        </div>
      );
    };
    return [
      columnHelper.accessor("name", {
        header: "Submissions Name",
        sorting: false,
        cell: (info) => (
          <div style={{ width: 180 }}>
            {info.getValue().length > 25 ? (
              <CustomPopover
                value={info.getValue().substring(0, 22) + "..."}
                popoverContent={renderIdPopoverContent(info.getValue())}
              />
            ) : (
              info.getValue()
            )}
          </div>
        ),
      }),
      columnHelper.accessor("row_count", {
        header: "Row Count",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorColor(info.getValue())}`} />
            {info.getValue()}
          </div>
        ),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("conforming_percentage", {
        header: "Conforming %",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("issues", {
        header: "Issues",
        cell: (info) => info.getValue()?.length || "--",
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("date", {
        header: "Submitted",
        cell: (info) => {
          return (
            <div className="table__text__stack" style={{ width: 180 }}>
              <div className="t--sm t--500">{info.row.original.submitted_by}</div>
              <div className="t--sm t--subtitle"> {info.getValue()} </div>
            </div>
          );
        },
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        selectedPage={selectedPage}
      />
    </div>
  );
}

export default MedicalClaimsTable;
