import _ from "lodash";
import EngineHandler from "../processors/engines/engine_handler";

export default class Attachment {
  constructor(id, file, kind, data, mapping, filterLists, salt) {
    this.id = id;
    this.file_id = file.id;
    this.name = file.data.name;
    this.kind = kind;

    this.filterLists = filterLists;
    this.mapping = mapping;

    this.data = data;
    this.originalData;
    this.formattedData;

    this.hasErrors;
    this.noMappingError;
    this.mappingMismatch;
    this.columns_with_errors;
    this.column_mapping_warnings;

    this.originalColumnNames;

    this.processData(data, salt);
  }

  processData = (data, salt) => {
    const result = EngineHandler(this.kind, {
      data,
      mapping: this.mapping.mappings,
      filterLists: this.filterLists,
      salt,
    });

    this.originalColumnNames = _.keys(result.data.originalData[0].data);

    this.columnNames = result.columnNames;
    this.noMappingError = result.noMappingError;
    this.mappingMismatch = result.mappingMismatch;
    this.originalData = result.data.originalData;
    this.formattedData = result.data.formattedData;
    this.hasErrors = result.hasErrors;
    this.column_mapping_warnings = result.column_mapping_warnings;
    this.columns_with_errors = result.columnsWithErrors;
  };
}
