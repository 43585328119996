import React from "react";
import Form from "./form";
import Billboard from "./billboard";

const Confirmations = ({ enrollment_id }) => {
  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <Form enrollment_id={enrollment_id} />
      </div>
    </div>
  );
};

export default Confirmations;
