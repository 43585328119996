import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const ConfirmationModal = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitting = () => {
    setSubmitting(true);
    
    if (submitting === false) {
      props.action();
    }
  };

  const renderTitle = () => {
    if (props.title) {
      return props.title;
    } else {
      return null;
    }
  };

  const renderMessage = () => {
    if (props.message) {
      return props.message;
    } else {
      return (
        "Please confirm you have verified column mappings are accurate and you have reviewed the selected data for submission. " +
        "A receipt is available for download providing a summary of your submitted data with a Beacon ID for your records."
      );
    }
  };

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading" style={{ "margin-inline-end": "16px" }}></div>;
    }
  };

  const renderModal = () => {
    if (props.displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__container animated fadeInUp--sm">
            <div className="modal__header">
              <div className="t--lg">Please Confirm Your Submission</div>
            </div>

            <div className="modal__body">
              <div className="container container--justify-center">
                <img src={verifyImage} width={220} style={{ marginBottom: 30 }} />
              </div>
              {/* {renderTitle()} */}
              <div className="t--subtitle">{renderMessage()}</div>
            </div>
            <div className="modal__footer">
              <div className="container container--space-between container--fw">
              <button className="button button--outlined" onClick={props.subAction}>
                Download Receipt
              </button>

              <div className="container container--gap-sm">
              <button
                className="button button--label"
                onClick={() => {
                  props.setModalState(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                id="confirmButton"
                onClick={() => {
                  handleSubmitting();
                }}
              >
                {renderLoader()}
                Confirm Submission
              </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return renderModal();
};
export default ConfirmationModal;
