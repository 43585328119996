import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { get } from "react-hook-form";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const FormInput = ({
  type = "text",
  name,
  label,
  placeholder = "",
  containerClass = "",
  size = "small",
  fullWidth = true,
  required = true,
  register,
  registerOptions = {},
  errors = {},
  multiline = false,
  rows = null,
  autoFocus = false,
  children = <></>,
  ...other
}) => {
  const errorForName = get(errors, name);

  // Default required message
  if (required && registerOptions.required === undefined) {
    registerOptions.required = "Field is required";
  }

  return (
    <div className={`form__group ${containerClass}`}>
      <label className="form__label">{label}</label>
      <CustomTextField
        type={type}
        placeholder={placeholder}
        size={size}
        fullWidth={fullWidth}
        variant="outlined"
        multiline={multiline}
        rows={rows}
        required={required}
        autoFocus={autoFocus}
        aria-invalid={errorForName}
        {...(register ? register(name, registerOptions) : {})}
        error={!!(errors && errorForName)}
        {...other}
      />
      {errors && errorForName && <div className="form__error--message">{errorForName.message}</div>}
      {children}
    </div>
  );
};

export default FormInput;
