import React, { useState } from "react";
import Form from "./form";
import ContinueForm from "./continue_form";
import Billboard from "./billboard";
import Notice from "/components/shared/Notice";

const Registrations = () => {
  const [selectedForm, setSelectedForm] = useState("new_form");
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const renderSelectedForm = () => {
    if (selectedForm === "new_form") {
      return <Form />;
    } else {
      return <ContinueForm setNotice={setNotice} />;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6 bg-light-gray">
          <Billboard />
        </div>
        <div className="col-6">
          <div className="form__registration animated fadeInUp--sm">
            <div className="btn__group__container">
              <div
                className={selectedForm == "new_form" ? "btn" : "btn btn--label"}
                onClick={() => setSelectedForm("new_form")}
              >
                New Registration
              </div>
              <div
                className={selectedForm == "continue_form" ? "btn" : "btn btn--label"}
                onClick={() => setSelectedForm("continue_form")}
              >
                Continue Registration
              </div>
            </div>
            {renderSelectedForm()}
          </div>
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default Registrations;
