import {
  isValidDateOfService,
  isValidDatePrescribed,
  isValidRXNumber,
  isValidQuantity,
  isValidNPI,
  isValidContractedEntityID,
  isValidFillNumber,
  isValidRxBin,
  isValidRxPCN,
  isValidCurrency,
} from "./data_validations";
import { isRequiredField } from "../schemas/pharmacy_claim";

const isValidPharmacyClaimField = (value, column, row, mapping) => {
  if (["claim_conforms_flag", "formatted_rx_number", "beacon_id"].includes(column)) {
    return true;
  }

  if (isRequiredField(column) && !value) {
    return false;
  }

  var cleanValue = value?.trim();

  switch (column) {
    case "date_of_service":
      return isValidDateOfService(cleanValue, row[mapping["date_prescribed"]]);
    case "date_prescribed":
      return isValidDatePrescribed(cleanValue, row[mapping["date_of_service"]]);
    case "rx_number":
      return isValidRXNumber(cleanValue);
    case "ndc":
      return true; // skip validations for column we filter by
    case "quantity":
      return isValidQuantity(cleanValue);
    case "prescriber_id":
      return isValidNPI(cleanValue);
    case "service_provider_id":
      return isValidNPI(cleanValue);
    case "contracted_entity_id":
      return isValidContractedEntityID(cleanValue);
    case "rx_bin_number":
      return isValidRxBin(cleanValue); 
    case "rx_pcn_number":
      return isValidRxPCN(cleanValue); 
    case "fill_number":
      return isValidFillNumber(cleanValue);
    case "reimbursement_amount":
      const formattedAmount = cleanValue?.replaceAll('$', '')?.replaceAll(',', '')
      return isValidCurrency(formattedAmount);
  }
};

export default isValidPharmacyClaimField;
