import React, { useState, useEffect,  useRef } from "react";
import { Close, AttachFileOutlined as AttachFileOutlinedIcon } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import FileUploadConfirmationModal from "../modals/UploadConfirmationModal";
import { Illustration, FileIcon } from "../images/svgs";
import MultiWithdrawModal from "../modals/MultiWithdrawModal";
import http from "./http";


const ReportsDraw = (props) => {
  const [disputing, setDisputing] = useState(false);
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDisputeModal, setDisplayDisputeModal] = useState(false);
  const [displayWithdrawModal, setDisplayWithdrawModal] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    setDisputing(null);
    setDocument(null);
  }, [props.dispute]);

  const handleFileChange = (e, row) => {
    const attachments = e.target.files;

    if (attachments) {
      setDocument(attachments[0]);
    }
  };

  const cancelDispute = () => {
    setDisputing(false);
    setDocument(null);
  }

  const putDispute = async () => {
    try {
      setIsLoading(true);

      var body = new FormData();
      body.append("document", document);

      await http.putData(`disputes/${props.dispute.id}`, body);
    } catch (error) {
      console.error({ error });
    } finally {
      setDisplayDisputeModal(false);
      setIsLoading(false);
      props.refresh();
    }
  };

  const withdrawClaims = async () => {
    try {
      setIsLoading(true);

      var body = new FormData();
      body.append("id", props.dispute.id);

      await http.postData(`/api/disputes/${props.dispute.id}/withdraw`, body);
    } catch (error) {
      console.error({ error });
    } finally {
      setDisplayWithdrawModal(false);
      setIsLoading(false);
      props.refresh();
    }
  };

  const renderWithdrawButton = () => {
    return (
      <>
        <div className="t--lg mb-2">
          Withdraw
        </div>
        <div className="t--sm t--gray mb-2">
          Withdraw all claims affected by this issue.
        </div>
        <div className="btn btn--small" onClick={() => setDisplayWithdrawModal(true)}>
          Withdraw Affected Claims
        </div>
        <div className="draw__divider" />
      </>
    );
  }

  const renderDisputeButton = () => {
    if (disputing) return null;
    if (props.dispute?.status.toLowerCase() == "under review") return null;

    return (
      <>
        <div className="t--lg mb-2">
          Dispute
        </div>
        <div className="t--sm t--gray mb-2">
          Dispute this issue and provide supporting documentation.
        </div>
        <div className="btn btn--small" onClick={() => setDisputing(true)}>
          Start a Dispute
        </div>
        <div className="draw__divider" />
      </>
    );
  }

  const renderUpload = () => {
    if (!document) {
      return (
        <>
          <button
            className="btn btn--small"
            style={{ padding: "2px 15px" }}
            onClick={() => {
              fileInputRef?.current?.click();
            }}
          >
            <AttachFileOutlinedIcon />{" "}
            <span className="t--sm">Upload a document</span>
          </button>
          <input
            type="file"
            style={{ display: "none" }}
            className="form-control"
            id="uploadAttachments"
            multiple={false}
            onChange={(e) => handleFileChange(e, props.dispute)}
            ref={fileInputRef}
          />
        </>
      )
    }
    return (
      <span className="t--sm draw__tile draw__tile--border flex__container flex__container--space-between">
        <div className="flex__container">
            <FileIcon style={{ fontSize: 14 }} />
            <div className="t--500 mx-3 my-2">{document.name}</div>
        </div>
        <Close className="t--sm" style={{cursor: "pointer"}} onClick={() => setDocument(null)} />
      </span>
    )
  }

  const renderDisputeForm = () => {
    if (!disputing) return null;
    if (props.dispute?.status.toLowerCase() == "under review") return null;

    return (
      <>
        <div className="t--lg mb-2">
          Start a Dispute
        </div>
        <div className="t--sm t--500 mb-3">
          Upload supporting documentation for this issue:
        </div>
        {renderUpload()}
        <div className="t--sm t--gray mt-3 mb-4">
          Disputes usually take between 7 - 14 business days to be reviewed.
          Please check the Dispute Center for updates.
        </div>
        <div className="flex__container flex__container--space-between">
          <div className={`btn btn--small ${document ? "" : "btn--disabled"}`} onClick={() => document ? setDisplayDisputeModal(true) : null}>
            Submit
          </div>
          <div className="btn btn--small btn--white" onClick={cancelDispute}>
            Cancel
          </div>
        </div>
        <div className="draw__divider" />
      </>
    );
  }

  const renderDisputeProgress = () => {
    if (props.dispute?.status.toLowerCase() != "under review") return null;

    return (
      <>
        <div className="t--lg mb-2">
          Dispute Progress
        </div>
        <div className="t--sm t--500 mb-3">
          Dispute is currently being reviewed by our team.
        </div>
        <span className="t--sm draw__tile draw__tile--border flex__container flex__container--space-between">
          <div className="flex__container">
            <div className="my-2">
              <FileIcon style={{ fontSize: 14 }} />
            </div>
            <div className="mx-3">
              <div className="t--500">{props.dispute.document_name}</div>
              <div>{props.dispute.submitted_by}</div>
              <div>{props.dispute.dispute_date}</div>
            </div>
          </div>
          <Close className="t--sm" style={{cursor: "pointer"}} onClick={() => setDocument(null)} />
        </span>
        <div className="t--sm t--gray mt-3 mb-4">
          Disputes usually take between 7 - 14 business days to be reviewed.
          Please check the Dispute Center for updates.
        </div>
        <div className="draw__divider" />
      </>
    );
  }

  const renderDescription = () => {
    return (
      <>
        <div className="t--lg mb-2">
          {props.dispute.issue.label}
        </div>
        <div className="t--sm">
          <div className="badge">{props.dispute.value1}</div>
          {props.dispute.description}
          {props.dispute.value2 ? (
            <div className="badge">{props.dispute.value2}</div>
          ) : null}
        </div>
        <div className="draw__divider" />
      </>
    );
  }

  const renderDetails = () => {
    return (
      <>
        <div className="t--sm t--500 flex__container flex__container--space-between " style={{padding:"0px 15px"}}>
          <span className="pt-1">Claims Affected: </span>
          <span className="pt-1">{props.dispute.count}</span>
        </div>
        <div className="t--sm t--500 flex__container flex__container--space-between pt-1" style={{padding:"0px 15px"}}>
          <span className="pt-1">Status: </span>
          <div className="badge ml-2">
            <div
              className={`badge__indicator badge__indicator--${props.getIndicatorColor(
                props.dispute.status
              )}`}
            />
            {props.dispute.status}
          </div>
        </div>
        <div className="draw__divider" />
      </> 
    );
  }

  const renderContent = () => {
    if (props.dispute) {
      return (
        <div className="draw__container">
          <div className="draw__tools__container">
            <div></div>
            <div className="btn btn--light" onClick={props.downloadAction} >Download</div>
            {/* <div className="btn btn--sqr">
              <Close fontSize="small" />
            </div> */}
          </div>

          {renderDescription()}
          {renderDetails()}
          {renderWithdrawButton()}
          {renderDisputeButton()}
          {renderDisputeForm()}
          {renderDisputeProgress()}
        </div>
      )
    }
    return (
      <div className="draw__container">
        <div className="draw__centered">
          <div className="t--lg mb-3 t--center">Select an Issue</div>
          <Illustration />
          <div className="t--md mt-3 t--gray t--center">Select an issue to view status and available actions.</div>
        </div>
      </div>
    )
  }

  const renderDisputeModal = () => {
    return (
      <FileUploadConfirmationModal
        displayModal={displayDisputeModal}
        setDisplayModal={setDisplayDisputeModal}
        title="Confirm Dispute"
        subtitle="Please confirm you want to submit the following document(s) for
        these reason codes and their details. Disputes usually take between 7 - 14 business days to be reviewed.
        Please check the Rebates Dashboard for Updates."
        body={{
          issue: props.dispute?.issue.label,
          details:
            props.dispute?.value1 +
            " " +
            props.dispute?.description +
            " " +
            props.dispute?.value2,
          claims_affected: props.dispute?.count,
          document: document,
        }}
        primaryAction={putDispute}
        secondaryAction={() => setDisplayDisputeModal(false)}
        isLoading={isLoading}
      />
    )
  }

  const renderWithdrawModal = () => {
    return (
      <MultiWithdrawModal
        displayModal={displayWithdrawModal}
        setDisplayModal={setDisplayWithdrawModal}
        title="Withdraw Affected Claims"
        subtitle="Please confirm you want to withdraw all claims associated with this issue. Claims withdrawn will not be processed even if resubmitted.
        You can re-instate claims by finding withdrawn claims in the reports views."
        body={{
          issue: props.dispute?.issue.label,
          details:
            props.dispute?.value1 +
            " " +
            props.dispute?.description +
            " " +
            props.dispute?.value2,
          claims_affected: props.dispute?.count,
        }}
        primaryAction={withdrawClaims}
        secondaryAction={() => setDisplayWithdrawModal(false)}
        isLoading={isLoading}
      />
    )
  }
    

  return (
    <>
      {renderContent()}
      {renderDisputeModal()}
      {renderWithdrawModal()}
    </>
  );
};

export default ReportsDraw;
