import React, { useState } from "react";
import httpClient from "/components/utils/httpClient";
import FormInput from "/components/shared/FormInput";

const AppForm = ({ setNotice, qr_code, mfa_secret }) => {
  const [code, setCode] = useState("");

  const handleSubmitCode = (e) => {
    e.preventDefault();
    httpClient
      .post("/mfa/authenticator/create", {
        code,
        mfa_secret,
      })
      .then((res) => (location.href = res.data.path))
      .catch(() => {
        setNotice({ kind: "error", open: true, message: "Error - Please enter a valid code" });
      });
  };

  const renderAuthForm = () => {
    return (
      <form onSubmit={handleSubmitCode}>
        <input type="hidden" name="mfa_secret" value={mfa_secret} />
        <div className="form__group">
          <div className="form__group__note">Enter your one-time code.</div>
          <FormInput
            placeholder="one-time code"
            id="code"
            name="code"
            onChange={(e) => setCode(e.currentTarget.value)}
          />
        </div>
        <div className="form__group">
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className={`btn ${code.length > 0 ? "" : "btn--disabled"}`}
              disabled={code.length == 0}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="line-divider"></div>
      </form>
    );
  };

  return (
    <>
      <img src={qr_code} style={{ width: 150, height: 150 }} />
      <div className="line-divider"></div>
      {renderAuthForm()}
    </>
  );
};

export default AppForm;
