import axios from "axios";

export default class Http {
  static post = (path, body) => {
    return axios
      .post(path, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
      })
      .then(function (response) {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(function (error) {
        return {
          status: error.status,
          data: error.response.data,
        };
      });
  };
}
