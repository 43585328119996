import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import AllocationsTable from "./tables/claim_allocations_table";
import StatementsTable from "./tables/statements_table";
import { Illustration } from "/components/images/svgs";
import { downloadReport } from "../../shared/downloadReport";
import { stringify } from "qs";

const PurchaseReport = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [credits, setCredits] = useState([]);
  const [header, setHeader] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    setIsLoading(true);
    var params = stringify(filters);
    history.pushState(filters, "", "/accounts/statement?" + params);
    Http.newPostQuery(props.query_path, { ...filters, ids: idFilter }, controller.signal).then((res) => {
      setCredits(res.credits);
      setHeader(res.header);
      setIsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter]);

  const download = async () => {
    await downloadReport({
      filters,
      setIsDownloading,
      download_path: props.download_path,
      idFilter,
    });
  };

  const renderAccount = () => {
    return (
      <>
        <div className="container container--end container--gap-sm container--mb-md">
          <div className="t__stack">
            <div className="t__stack__subtitle t--right">Balance</div>
            <div className="t--lg t__stack__title t--right">{header?.total}</div>
          </div>
        </div>

        <StatementsTable
          data={credits}
          isLoading={isLoading}
          handleRowClick={(event) => setSelected(event.row.original)}
          getIndicatorColor={getIndicatorColor}
        />
      </>
    );
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "green";
      case "under review":
        return "orange";
      default:
        return "blue";
    }
  };

  const renderPackage = () => {
    if (selected == null || selected.package == null) {
      return (
        <>
          <div className="section">Line Item Details</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No Line Item Selected</div>
          </div>
        </>
      );
    }
    if (selected.kind == "payment") {
      return (
        <>
          <div className="section">Line Item Details</div>
          <div className="t--sm t--light">Payment details not yet available</div>
        </>
      );
    }

    return (
      <>
        <div className="section">Line Item Details</div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Invoice Number</div>
          <div className="t--sm">{selected?.package?.invoice_number}</div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Package Number</div>
          <div className="t--sm">{selected?.package?.package_number}</div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Units</div>
          <div className="t--sm">{selected?.package?.units}</div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">NDC</div>
          <div className="t__stack">
            <div className="t--sm t--right">{selected?.package?.product}</div>
            <div className="t--sm t--light t--right">{selected?.package?.ndc}</div>
          </div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Date</div>
          <div className="t--sm">{selected?.package?.submitted_date}</div>
        </div>
        <div className="section">Allocations</div>
        <AllocationsTable data={selected?.package?.allocations} />
      </>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Statements</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            defaultFilters={props.filters}
            isLoading={isDownloading}
            filterLists={props.filterLists}
            mandatoryFilters={["entity"]}
            disabledFilters={{ submission: true }}
            download={download}
          />
        </div>
      </div>
      <div className="body__content">
        {/* <div className="panel--menu">{renderCards()}</div> */}
        <div className="panel panel--pad panel--no-btm">{renderAccount()}</div>
        <div className="panel panel--pad panel--info panel--no-btm">{renderPackage()}</div>
      </div>
    </>
  );
};

export default PurchaseReport;
