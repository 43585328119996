import React from "react";
import Form from "./form";
import Billboard from "./billboard";

const SessionsForm = () => {
  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <Form />
      </div>
    </div>
  );
};

export default SessionsForm;
