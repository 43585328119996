import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller, useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";
import _ from "lodash";
import httpClient from "/components/utils/httpClient";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const MobileForm = ({ setNotice }) => {
  const [displayAuthForm, setDisplayAuthForm] = useState(false);
  const [code, setCode] = useState("");

  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const watchedPhone = watch("user[phone_number]", "");

  const handleSubmitNumber = (e) => {
    e.preventDefault();

    httpClient
      .post("/mfa/mobile", { number: watchedPhone })
      .then(() => {
        setNotice({ kind: "success", open: true, message: "One-Time Code successfully sent" });
        setDisplayAuthForm(true);
      })
      .catch(() => {
        setNotice({
          kind: "error",
          open: true,
          message: "Error - Please enter a valid Cell Number",
        });
        setDisplayAuthForm(false);
      });
  };

  const handleSubmitCode = (e) => {
    e.preventDefault();

    httpClient
      .post("/mfa/mobile/verify", { code })
      .then((res) => (location.href = res.data.path))
      .catch((err) => {
        setNotice({ kind: "error", open: true, message: "Error - " + err.response.data.notice });
      });
  };

  const renderAuthForm = () => {
    if (displayAuthForm) {
      return (
        <form onSubmit={handleSubmitCode}>
          <div className="form__group">
            <div className="form__group__note">Enter the one-time code for verification.</div>
            <CustomTextField
              placeholder="One-Time Code"
              size="small"
              variant="outlined"
              id="code"
              name="code"
              onChange={(e) => setCode(e.currentTarget.value)}
              fullWidth
              required
            />
          </div>
          <div className="form__group">
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className={`btn ${code.length > 0 ? "" : "btn--disabled"}`}
                disabled={code.length == 0}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="line-divider"></div>
        </form>
      );
    }
  };

  const phoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  return (
    <>
      <form onSubmit={(e) => handleSubmitNumber(e)}>
        <div className="form__group">
          <div className="form__group__note mt-3">
            Enter the mobile number where you would like to receive your code.
          </div>
          <Controller
            name="user[phone_number]"
            control={control}
            rules={{
              required: "Field is required",
              pattern: {
                value: /^\d{10}$/i,
                message: "Invalid phone",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <MaskedInput
                  mask={phoneMask}
                  showMask={false}
                  value={value}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/\D/g, "");
                    onChange(rawValue);
                  }}
                  onBlur={onBlur}
                  render={(ref, props) => (
                    <CustomTextField
                      inputRef={ref}
                      {...props}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                      }}
                      placeholder="Phone Number"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      error={errors.user ? _.has(errors.user, "phone_number") : false}
                    />
                  )}
                />
                <input id="number" type="hidden" name="user[phone_number]" value={value} />
              </>
            )}
          />
          {errors.user?.phone_number && (
            <div className="form__error--message">{errors.user?.phone_number.message}</div>
          )}
        </div>
        <div className="form__group">
          <div className="d-flex justify-content-end">
            <button className={`btn ${isValid ? "" : "btn--disabled"}`} disabled={!isValid}>
              Send Code
            </button>
          </div>
        </div>
        <div className="line-divider"></div>
      </form>
      {renderAuthForm()}
    </>
  );
};

export default MobileForm;
