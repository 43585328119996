import React, { useState } from "react";
import _ from "lodash";
import shapes from "/images/shapes.svg";
import logo from "/images/logo_light.png";
import httpClient from "/components/utils/httpClient";

const WelcomeModal = ({ first_name, notification_id }) => {
  const [open, setOpen] = useState(true);

  const handleDismiss = () => {
    setOpen(false);
    dismissNotification();
  };

  const dismissNotification = () => {
    httpClient.put(`/notifications/${notification_id}`);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="modal__header">
          <div className="t__stack">
            <div className="t__stack__title t--lg">Hi {first_name},</div>
            <div className="t__stack__subtitle">Welcome to Beacon!</div>
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <div className="modal__body__img modal__body__img--bg-dark">
          <img
            src={logo}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              width: 100,
              margin: "auto",
              opacity: 0.05,
            }}
          />
          <img src={shapes} style={{ position: "absolute", top: 0, left: 30, width: 120 }} />
          <div className="container container--columns container--justify-center container--align-center container--fill">
            <div className="t__stack">
              <div className="t__stack__subtitle t--light t--white">Let's get started</div>
              <div className="t__stack__title t--xl t--white ">Welcome to Beacon</div>
              <div className="t__stack__subtitle t--light t--white">Illuminating Pharmaceutical Pricing</div>
            </div>
          </div>

          <div className="shape__container" style={{ position: "absolute", bottom: -5, right: 30 }}>
            <div className="shape__square shape__square--sm"></div>
            <div className="shape__square shape__square--tny shape__square--tny--right"></div>
          </div>
        </div>
        <p>
          To help you get started, please review the Beacon Welcome Package. This onboarding document provides important
          information about configuring your Beacon account and establishing your bank account information for credit
          payments. Please complete the list of next steps outlined in the document to ensure your covered entity is
          prepared for submission.
          <br />
          <br />
          Please reach out to Beacon Support should you have any questions.
        </p>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer flex--space-between">
        <a
          href="https://support.beaconchannelmanagement.com/en/articles/9691926-welcome-to-beacon-channel-management"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="button button--outlined">
            <div className="material-symbols-outlined">arrow_right_alt</div>
            View Welcome Package
          </button>
        </a>

        <button className="button" onClick={() => handleDismiss()}>
          Dismiss
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && handleDismiss();
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      );
    }
  };

  return <>{renderModal()}</>;
};

export default WelcomeModal;
