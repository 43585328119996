import { VALID_DATE_FORMATS } from "../validators/data_validations";

export const validationDetails = [
  {
    key: "missing_fields",
    title: "Missing/Invalid Fields",
    description:
      "Your upload has some missing or invalid fields, which have been highlighted in red. Please correct these fields before submitting your file.",
  },
  {
    key: "invalid_date",
    title: "Missing/Invalid Date",
    description:
      `Your upload has some missing or invalid dates, which have been highlighted in red. Accepted date formats include ${VALID_DATE_FORMATS.map(e => `"${e}"`).join(", ")}.`,
  },
  {
    key: "mapping_mismatch",
    title: "Mapping Mismatch",
    description:
      "Some columns in your mapping are not present in the file. Please check your mappings for issues.",
  }
];

export const getValidationDetails = (key) => {
  return validationDetails.find((detail) => detail.key === key);
};
