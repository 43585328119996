import React, { useMemo } from "react";
import { BarChart, Bar, ResponsiveContainer, ReferenceLine } from "recharts";

const placeholderData = [
  {
    uv: 8,
    pv: -5,
  },
  {
    uv: 10,
    pv: -3,
  },
  {
    uv: 7,
    pv: -8,
  },
  {
    uv: 10,
    pv: -5,
  },
  {
    uv: 9,
    pv: -2,
  },
  {
    uv: 3,
    pv: -2,
  },
  {
    uv: 8,
    pv: -5,
  },
  {
    uv: 7,
    pv: -3,
  },
  {
    uv: 3,
    pv: -5,
  },
  {
    uv: 10,
    pv: -2,
  },
  {
    uv: 8,
    pv: -3,
  },
  {
    uv: 10,
    pv: -2,
  },
];

const Claims = ({ data }) => {
  const chartData = useMemo(() => {
    if (!data) {
      return placeholderData;
    }
    return data.map((item) => ({
      uv: item.conforming_claims,
      pv: -item.non_conforming_claims,
    }));
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart data={chartData} stackOffset="sign" margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <ReferenceLine y={0} stroke="#DEE7EE" />
        <Bar
          dataKey="pv"
          stackId="a"
          barSize={12}
          radius={[6, 6, 0, 0]}
          fill={chartData != placeholderData ? "#F50057" : "#DEE7EE"}
        />
        <Bar
          dataKey="uv"
          stackId="a"
          barSize={12}
          radius={[6, 6, 0, 0]}
          fill={chartData != placeholderData ? "#0560FD" : "#DEE7EE"}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Claims;
