import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <p>
        Please complete the form with the requested information. One of our team members will be back in touch with you
        within 24 hours.
      </p>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
