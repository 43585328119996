import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";
import CustomPopover from "/components/shared/popover";

function AllocationsTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  setNotice,
  handleRowClick,
  selectedRowIndex,
}) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    const idPopoverClick = (value) => {
      navigator.clipboard.writeText(value);
      setNotice({
        kind: "success",
        open: true,
        message: "ID copied to clipboard",
      });
    }
    
    const renderIdPopoverContent = (value) => {
      return (
        <div className='content p--5 flex popover__copy' >
          <span className="material-symbols-outlined">file_copy</span>
          <span className="t--tny">{value}</span>
        </div>
      )
    }

    return [
      columnHelper.accessor("package_id", {
        header: "Package ID",
        sorting: false,
        cell: (info) => <div className="t--fw-500"><CustomPopover value={info.getValue().substring(0,12)} popoverContent={renderIdPopoverContent(info.getValue())} hoverable={true} onClickCallback={() => idPopoverClick(info.getValue())} /></div>,
      }),
      columnHelper.accessor("quantity", {
        header: "Quantity",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("date", {
        header: "Date",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        minRows={0}
        disablePagination
      />
    </div>
  );
}

export default AllocationsTable;
