import React, { useState, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import Table from "/components/shared/table";

function EntityDetailsTable({ shippingAddresses }) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);

    const toggleRow = (index) => {
      setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return [
      columnHelper.accessor("name", {
        header: "Location",
        sorting: false,
        cell: (info) => (
          <>
            <div>{info.getValue()}</div>
            <div className="t--light">{info.row.original.address}</div>
          </>
        ),
      }),
      columnHelper.accessor("npis", {
        header: () => <span className="no-caps">NPIs</span>,
        cell: (info) => {
          const isExpanded = expandedRowIndex === info.row.index;
          const data = isExpanded ? info.getValue().split(" ") : info.getValue().split(" ").slice(0, 19);

          return (
            <div className="entities-cell">
              {data.map((item, index) => {
                const isLastItem = index === data.length - 1;
                const trimmedItem = item.trim();

                // Check if item already ends with a comma, if not, add a comma (except for the last item)
                const formattedItem = !isLastItem
                  ? trimmedItem.endsWith(",")
                    ? trimmedItem
                    : trimmedItem + ","
                  : trimmedItem.replace(/,$/, "");

                return (
                  <span key={index} className="entity-item">
                    {formattedItem}
                  </span>
                );
              })}

              {info.getValue().split(" ").length > 19 && (
                <div className="show-more" onClick={() => toggleRow(info.row.index)} style={{ color: "#0560fd" }}>
                  {isExpanded ? "Show less" : "Show more"}
                </div>
              )}
            </div>
          );
        },
        filterFn: "equalsString",
        sorting: false,
      }),
    ];
  });

  return (
    <div className="table--scroll">
      <Table
        data={shippingAddresses}
        columns={columns}
        enableHover={true}
        noResultsMessage="The current search and filter criteria returned no results"
        layoutFixed={true}
      />
    </div>
  );
}

export default EntityDetailsTable;
