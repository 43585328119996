import React from "react";
import cx from "classnames";

const SubmitButton = ({ title = 'Submit', isDisabled = false, className = "btn btn--fw" }) => {
  return (
    <button type="submit" className={cx(className, { 'btn--disabled': isDisabled })} disabled={isDisabled}>
      {title}
    </button>
  );
};

export default SubmitButton;
