import React, { useState, useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const HistorySkeleton = ({ rows }) => {
  return [...Array(rows)].map((_, i) => (
    <ThemeProvider theme={Theme}>
      <Timeline style={{ padding: 0, width: "296px" }} className="animated delay-2 fadeIn">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ padding: "3px" }} className={`timeline timeline--not-considered`} />
            {i == rows - 1 ? "" : <TimelineConnector className={`timeline timeline--not-considered`} />}
          </TimelineSeparator>
          <TimelineOppositeContent
            className={history.not_considered ? "t--grey" : ""}
            style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
          >
            <Skeleton animation="wave" sx={{ fontSize: 12, width: "100%", textAlign: "left" }}></Skeleton>
            <Skeleton animation="wave" sx={{ fontSize: 12, width: "60%", textAlign: "left" }}></Skeleton>
            <Skeleton animation="wave" sx={{ fontSize: 12, width: "100%", textAlign: "left" }}></Skeleton>

            <div className="badge badge--disabled mb-1">
              <div className="badge__indicator badge__indicator--grey" />
              <Skeleton animation="wave" sx={{ fontSize: 12, width: "80px" }}></Skeleton>
            </div>
          </TimelineOppositeContent>
        </TimelineItem>
      </Timeline>
    </ThemeProvider>
  ));
};
