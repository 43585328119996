import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    border: "1px solid #DEE5EB",
    boxShadow: "none",
    padding: "5px",
  },
}));

export const IssuesToolTip = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        className="badge"
        aria-owns={open ? props.row.index : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div
          className={`badge__indicator ${
            _.size(props.getValue()) > 0 ? "badge__indicator--red" : "badge__indicator--green"
          }`}
        />
        {_.size(props.getValue())}
      </div>
      {props.getValue().length <= 0 ? null : (
        <Popover
          id={props.row.index}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          PaperProps={{ onMouseLeave: handlePopoverClose }}
          onClose={handlePopoverClose}
          onMouseLeave={handlePopoverClose}
          //disableRestoreFocus
        >
          {props.getValue().map((item) => (
            <div className="t--sm p--5 flex" key={item.label}>
              <div className="badge__indicator badge__indicator--red"></div> {item.label}
            </div>
          ))}
        </Popover>
      )}
    </>
  );
};
