import _ from "lodash";

// Returns the mapping whose column values most frequently match the attached file's column values
export const selectMapping = (mappings, data) => {
  const doStringsMatch = (str1, str2) => {
    const normalizeString = (str) => str.replace(/[^a-zA-Z]/g, "").toLowerCase();

    const normalizedString1 = str1 ? normalizeString(str1) : "";
    const normalizedString2 = str2 ? normalizeString(str2) : "";

    return normalizedString1 === normalizedString2;
  };

  const fileHeaders = _.keys(data[0]);
  var fieldMappingScores = [];

  _.forEach(mappings, (fieldMapping, index) => {
    // tracks whether the file headers includes the mapping keys
    var score1 = 0;

    // tracks whether the mapping key and value are matching normalized strings
    var score2 = 0;

    _.forEach(fieldMapping.mappings, (value, key) => {
      if (fileHeaders.includes(value)) {
        score1 += 1;
      }
      if (doStringsMatch(key, value)) {
        score2 += 1;
      }
    });

    const finalScore = score1 + score2;

    fieldMappingScores[index] = { id: fieldMapping.id, score: finalScore };
  });

  var scores = _.map(fieldMappingScores, "score");

  if (!scores.length) {
    return [];
  }

  var mappingDetails = _.find(fieldMappingScores, (item) => item.score == Math.max(...scores));

  return _.find(mappings, ["id", mappingDetails.id]);
};

export const getMapping = (mappings, name) => {
  return _.find(mappings, ["name", name]);
};
