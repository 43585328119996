import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";
import CloseIcon from "@mui/icons-material/Close";

const CofirmationModal = (props) => {
  const renderHeader = () => {
    return (
      <div className="modal__content__header">
        <div
          className="modal__content__header--container flex"
          style={{
            background: "#F2F5F8",
            height: 80,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {props.header}
        </div>
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div className="btn btn--sqr btn--sqr--modal" onClick={() => props.setDisplayModal(false)}>
        <CloseIcon className="t--sm" />
      </div>
    );
  };
  const renderBody = () => {
    return (
      <div className="flex flex-col item-start" style={{ marginTop: 80 }}>
        <div className="t--xl t--primary mt-1">{props.title}</div>

        <div className="modal__content__body">{props.body}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div className="modal__content__footer p--0">{props.footer}</div>;
  };

  return (
    <div className="modal__background" data-testid="modal-continer">
      <div className="modal__container animated fadeInUp--sm" style={{ width: props.width ? props.width : "680px" }}>
        <div className="modal__content modal__content--padded">
          {renderClose()}
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default CofirmationModal;
