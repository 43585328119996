import React, { useState, useEffect, forwardRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

const DateRange = ({ visible, value, handleChange }) => {
  // refactor - dont bind if component is not visible
  if (!visible) {
    return null;
  }
  const [dateRange, setDateRange] = useState(
    [moment(value["start"]).toDate(), moment(value["end"]).toDate()]
  );

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const dates = {start: moment(dateRange[0]).format("YYYY-MM-DD"), end: moment(dateRange[1]).format("YYYY-MM-DD")};
      handleChange("date_range", dates);
    }
  }, [dateRange]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const startDate = value.split("-")[0];
    const endDate = value.split("-")[1];

    return (
      <div className="filter__item" onClick={onClick} ref={ref}>
        <div className="filter__item__label">
          <span className="material-symbols-outlined">calendar_month</span>
          Date Range
        </div>

        <div className="filter__item__type">start</div>
        <div className="filter__item__value">{startDate}</div>
        <div className="filter__item__type">end</div>
        <div className="filter__item__value">{endDate}</div>
      </div>
    );
  });

  return (
    <DatePicker
      selectsRange={true}
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      onChange={setDateRange}
      monthsShown={2}
      showPopperArrow={false}
      customInput={<CustomInput />}
      maxDate={new Date()}
      popperPlacement="bottom-start"
    />
  );
};

export default DateRange;
