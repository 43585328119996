import React from "react";
import { styled } from "@mui/material/styles";
import { Controller, get } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontSize: 14,
  },
});

const FormCheckbox = ({
  name,
  label,
  containerClass = "",
  control,
  required = true,
  errors = {},
}) => {
  const errorForName = get(errors, name);

  const rules = required ? { required: "Field is required" } : {};

  return (
    <div className={`form__group ${containerClass}`}>
      <CustomFormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={false}
            rules={rules}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                name={name}
                checked={value}
                onChange={onChange}
                color="primary"
                sx={{
                  color: "#3246D3",
                  "&.Mui-checked": {
                    color: "#3246D3",
                  },
                }}
                required={required}
                aria-invalid={errorForName}
              />
            )}
          />
        }
        label={label}
      />
      {errors && errorForName && <div className="form__error--message">{errorForName.message}</div>}
    </div>
  );
};

export default FormCheckbox;
