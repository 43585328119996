import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Box } from "@mui/material";

const Search = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const renderDropdownLabel = () => {
    if (props.searchTerms.filterQuery.length === 0) {
      return "Status";
    }

    return generateFilterLabel();
  };

  const renderSelectedOption = (option, i) => {
    return (
      <div
        key={i}
        className="filterbox__menu__item filterbox__menu__item--selected"
        onClick={() => props.setIssueFilter(option.value)}
      >
        <div>{option.label}</div>
        <div className="filterbox__menu__item__selected__icon">
          <div className="solid solid-budicon-check-ui" />
        </div>
      </div>
    );
  };

  const renderOption = (option, i) => {
    return (
      <div key={i} className="filterbox__menu__item" onClick={() => props.setIssueFilter(option.value)}>
        <div>{option.label}</div>
      </div>
    );
  };

  const renderIssuesOptions = () => {
    return props.issues.map((option, i) => {
      if (props.searchTerms.filterQuery.includes(option.value)) {
        return renderSelectedOption(option, i);
      } else {
        return renderOption(option, i);
      }
    });
  };

  const renderFilterMenu = () => {
    if (!menuOpen) return;

    return <div className="filterbox__menu">{renderIssuesOptions()}</div>;
  };

  const renderIssueFilter = () => {
    if (!props.filterable) return;

    return (
      <div className="filterbox__container filterbox--ml" onClick={() => setMenuOpen(!menuOpen)}>
        <div className="filterbox--bold-text"> {renderDropdownLabel()} </div>
        {renderFilterMenu()}
        <div className="filterbox__icon">
          <i className="solid solid-budicon-chevron-bottom" />
        </div>
      </div>
    );
  };

  return (
    <div className="searchbar__wrapper">
      <div className="searchbar__container">
        <div className="searchbar__icon">
          <i className="solid solid-budicon-search-a" />
        </div>
        <input
          className="searchbar__input"
          type="text"
          placeholder="Search"
          onChange={(e) => props.setSearch(e.target.value)}
        />
      </div>
      {renderIssueFilter()}
    </div>
  );
};

export default Search;
