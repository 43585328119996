import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Billboard from "./Billboard";
import FormInput from "/components/shared/FormInput";
import FormSelect from "/components/shared/FormSelect";
import Notice from "/components/shared/Notice";
import httpClient from "../utils/httpClient";

const ContactUs = () => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const defaultValues = {
    contact_request: {
      first_name: "",
      last_name: "",
      email: "",
      organization_type: "",
      message: "",
      id_340b: "",
    },
  };

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues, mode: "onChange" });

  const organizationTypeList = [
    { label: "Covered Entity", value: "Covered Entity" },
    { label: "Manufacturer", value: "Manufacturer" },
    { label: "Vendor", value: "Vendor" },
    { label: "Media", value: "Media" },
    { label: "Other", value: "Other" },
  ];

  const watchedOrganizationType = watch("contact_request.organization_type");
  const show340Field = watchedOrganizationType === "Covered Entity";

  const handleValidSubmit = (value) => {
    httpClient
      .post("/support", value)
      .then((res) => {
        setNotice({ kind: "success", open: true, message: "Your message was received." });
        reset(defaultValues);
      })
      .catch((err) => {
        setNotice({ kind: "error", open: true, message: err.message });
      });
  };

  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <div className="form__registration animated fadeInUp--sm">
          <form method="post" onSubmit={handleSubmit(handleValidSubmit)}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector("[name=csrf-token]").content}
            />
            <div className="form__section">Contact Details</div>
            <div className="form__group__multi">
              <FormInput
                label="First Name"
                placeholder="John"
                name="contact_request.first_name"
                register={register}
                errors={errors}
              />
              <FormInput
                label="Last Name"
                placeholder="Doe"
                name="contact_request.last_name"
                register={register}
                errors={errors}
              />
            </div>
            <FormInput
              label="Email"
              placeholder="Work Email Address"
              name="contact_request.email"
              register={register}
              registerOptions={{
                required: "Field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              errors={errors}
            />
            <div className="form__group">
              <label className="form__label">Organization Type</label>
              <FormSelect
                placeholder="Please select your organization type"
                options={organizationTypeList}
                required={true}
                control={control}
                errors={errors}
                name="contact_request.organization_type"
              />
            </div>
            {show340Field && (
              <FormInput
                label="340B ID"
                placeholder=""
                name="contact_request.id_340b"
                required={show340Field}
                register={register}
                errors={errors}
              />
            )}
            <div className="form__section">Message</div>
            <FormInput
              label="Message"
              placeholder="Your message"
              name="contact_request.message"
              multiline={true}
              rows={5}
              register={register}
              errors={errors}
            />
            <div className="form__group">
              <button type="submit" className="btn btn--fw">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ContactUs;
