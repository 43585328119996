import _ from "lodash";

export const columnSchema = {
  invoice_number: null,
  invoice_date: null,
  ndc: null,
  contracted_entity_id: null,
  service_provider_id: null,
  quantity: null,
  account_number: null,
  wholesaler_name: null,
};

export const columnDetails = [
  {
    name: "invoice_number",
    title: "Invoice Number",
    description:
      "The invoice number assigned by the wholesaler for the purchase made by the 340B covered entity.",
    required: true,
    order: 1,
  },
  {
    name: "invoice_date",
    title: "Invoice Date",
    description:
      "The date on which the invoice for the purchase was issued by the wholesaler to the 340B covered entity.",
    required: true,
    order: 2,
  },
  {
    name: "ndc",
    title: "NDC-11",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true,
    order: 3,
  },
  {
    name: "contracted_entity_id",
    title: "340B ID",
    description:
      "The 340B ID of the covered entity that placed the purchase. This is usually the 340B ID of the covered entity under which you registered.",
    required: true,
    order: 4,
  },
  {
    name: "service_provider_id",
    title: "Ship-To Pharmacy (NPI)",
    description: "The unique public NPI for the ship to pharmacy.",
    required: true,
    order: 5,
  },
  {
    name: "quantity",
    title: "Quantity",
    description:
      "The number of packages of the NDC-11 purchased by the 340B covered entity.",
    required: true,
    order: 6,
  },
  {
    name: "account_number",
    title: "Account Number",
    description:
      "Account number assigned by the wholesaler and used for the purchase.",
    required: true,
    order: 8,
  },
  {
    name: "wholesaler_name",
    title: "Wholesaler Name",
    description:
      "Name of the wholesaler through which the covered entity made the purchase.",
    required: true,
    order: 9,
  },
];

export const tableHeaders = columnDetails.map((column) => column.name);
export const sortedColumnDetails = columnDetails.sort(
  (a, b) => a.order - b.order
);
export const required = columnDetails
  .filter((column) => column.required)
  .map((column) => column.name);
export const notRequired = columnDetails
  .filter((column) => !column.required)
  .map((column) => column.name);
export const isRequiredField = (column) => {
  return required.includes(column);
};

export class Invoice {
  constructor() {
    this.hasErrors = false;

    this.data = {
      invoice_number: null,
      invoice_date: null,
      ndc: null,
      contracted_entity_id: null,
      service_provider_id: null,
      quantity: null,
      account_number: null,
      wholesaler_name: null,
    };

    this.columns_with_errors = {
      invoice_number: false,
      invoice_date: false,
      ndc: false,
      contracted_entity_id: false,
      service_provider_id: false,
      quantity: false,
      account_number: false,
      wholesaler_name: false,
    };
  }
}
