import React from "react";

export const PaginationControls = ({ table }) => {
  const totalCount = table.getFilteredRowModel().rows.length;
  const pageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalCount);

  return (
    <div className="container container--justify-center">
      <div className="container floating-pagination container--justify-center">
        <button
          className="button button--sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Prev
        </button>
        <div className="t t--sm t--center" style={{ minWidth: 120 }}>
          {start} - {end} of {totalCount} results
        </div>
        <button className="button button--sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </button>
      </div>
    </div>
  );
};
