import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FileUpload from "../components/file_upload";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";
import _ from "lodash";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const RegistrationForm = ({ selectedEntity, control, errors }) => {
  return (
    <>
      <div className="form__group">
        <label className="form__label">Employer Identification Number (EIN)</label>
        <Controller
          name="registration.ein"
          control={control}
          rules={{
            required: "Field is required",
            pattern: {
              value: /^(\d{2}-\d{7})$/i,
              message: "Invalid EIN",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <MaskedInput
                mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                showMask={false}
                value={value}
                onChange={onChange}
                render={(ref, props) => (
                  <CustomTextField
                    inputRef={ref}
                    placeholder="12-3456789"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    required={true}
                    {...props}
                  />
                )}
              />
              <input type="hidden" name="registration.ein" value={value} />
            </>
          )}
        />
        {errors.registration?.ein && <div className="form__error--message">{errors.registration.ein.message}</div>}
      </div>
      <div className="form__group">
        <label className="form__label">IRS EIN Letter (CP-575)</label>
        <FileUpload
          entityID={selectedEntity}
          id={`ein_${selectedEntity}`}
          name="registration.ein_letter"
          control={control}
          accept="image/*,.pdf"
        />
      </div>
      <div className="form__group">
        <label className="form__label">Articles of Incorporation</label>
        <FileUpload
          entityID={selectedEntity}
          id={`aoi_${selectedEntity}`}
          name="registration.article_of_incorporation"
          control={control}
          accept="image/*,.pdf"
        />
      </div>
    </>
  );
};

export default RegistrationForm;
