import React, { useMemo } from "react";
import SubmissionsTable from "./tables/submissions_table";

const Submissions = ({submissions}) => {

  return (
    <SubmissionsTable
      data={submissions}
      isLoading={false}
      searchQuery={""}
      setSearchQuery={() => {}}
      customerFilter={""}
      statusFilter={""}
      setNotice={() => {}}
      handleRowClick={() => {}}
      selectedRowIndex={null}
    />
  );
};

export default Submissions;
