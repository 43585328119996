import React from "react";

import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

const ceCustomSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "0px 0 20px",
  }),
  control: (base) => ({
    ...base,
    height: 40,
    borderColor: "#DEE7EE",
    margin: 0,
    borderRadius: 8,
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.035)",
  }),
  valueContainer: (base) => ({
    ...base,
    height: 40,
    padding: "0 12px",
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 14,
    color: "#718291",
    fontWeight: "400",
  }),
};

const EntitiesSelect = (props) => {
  const loadOptions = (query) => fetchSearchTerm(query);

  const debouncedLoadOptions = debounce(loadOptions, 800, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({
      query: query,
    }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const onSelectChange = (val) => {
    const newValue = val.map(c => c.value).concat(props.value || []);
    props.onChange([...new Set(newValue)]);
  };

  return (
    <AsyncSelect
      isMulti
      name="entity_id"
      placeholder={"Search by 340B ID or entity name"}
      value={""}
      openMenuOnFocus={false}
      openMenuOnClick={false}
      styles={ceCustomSelectStyles}
      defaultOptions={false}
      isClearable={false}
      onChange={onSelectChange}
      loadOptions={(query) => debouncedLoadOptions(query)}
      components={{ DropdownIndicator: null, IndicatorSeparator: null }}
      inputRef={props.inputRef}
    />
  );
};

export default EntitiesSelect;
