import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "/components/shared/FormInput";
import SubmitButton from "~/components/shared/SubmitButton";

const UserInviteModal = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("user");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };
  const roles = [
    {
      label: "User",
      value: "user",
    },
    {
      label: "Administrator",
      value: "admin",
    },
  ];

  const {
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const renderSelectedIndicator = (role) => {
    if (role.value == selectedRole) {
      return <div className="indicator__selected"></div>;
    }
  };

  const renderRoles = () => {
    return roles.map((role) => {
      return (
        <div
          className={
            selectedRole == role.value
              ? "list__item list__item--selected list__item--no-mgn"
              : "list__item list__item--no-mgn list__item--align-right"
          }
          onClick={() => setSelectedRole(role.value)}
        >
          {renderSelectedIndicator(role)}
          {role.label}
        </div>
      );
    });
  };

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Invite a New Team Member</div>
          <div className="t__stack__subtitle">
            To send an invite to join Beacon, enter your team member’s contact information and select their role.
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <div className="form__group">
          <div className="t__stack">
            <div className="t__stack__subtitle">{props.id_340b}</div>
            <div className="t__stack__title">{props.entity_name}</div>
          </div>
        </div>
        <div className="form__group__multi">
          <FormInput
            label="First Name"
            placeholder="John"
            size="small"
            name="invite[first_name]"
            registerOptions={{
              required: "Field is required",
            }}
            register={register}
            errors={errors}
          />
          <FormInput
            label="Last Name"
            placeholder="Doe"
            size="small"
            name="invite[last_name]"
            registerOptions={{
              required: "Field is required",
            }}
            register={register}
            errors={errors}
          />
        </div>
        <FormInput
          label="Email"
          placeholder="Email"
          size="small"
          name="invite[email]"
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          register={register}
          errors={errors}
        />
        <div className="form__group">
          <label className="form__label">Role</label>
          <div className="form__group__note">
            Only Administrators can invite other team members and provide Bank Account information.
          </div>

          <div className="container container--gap-md">{renderRoles()}</div>
          <input type="hidden" name="invite[role]" value={selectedRole} />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--label" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <SubmitButton title="Send Invite" className="button" isDisabled={!isValid || isSubmitting} />
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form method="post" action={`/entities/${props.id_340b}/users`} onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="authenticity_token"
                value={document.querySelector("[name=csrf-token]").content}
              />
              <input type="hidden" name="invite[entity_id]" value={props.id_340b} />
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="button" onClick={() => setOpen(!open)}>
        Invite New User
      </div>
      {renderModal()}
    </>
  );
};

export default UserInviteModal;
