export const hasAtLeastOneLower = (val) => {
  return val.match(/.*[a-z].*/) != null;
};

export const hasAtLeastOneUpper = (val) => {
  return val.match(/.*[A-Z].*/) != null;
};

export const hasAtLeastOneNumber = (val) => {
  return val.match(/.*[0-9].*/) != null;
};

export const hasAtLeastOneSpecial = (val) => {
  return val.match(/.*[\W_].*/) != null;
};

export const hasAtLeastTwelveChars = (val) => {
  return val.length >= 12;
};

export const hasMatchingPasswords = (password, confirmPassword) => {
  if (!password || !confirmPassword) {
    return false;
  }
  return password === confirmPassword;
};

export const isValidPassword = (val) => {
  if (val) {
    return (
      hasAtLeastOneLower(val) &&
      hasAtLeastOneUpper(val) &&
      hasAtLeastOneNumber(val) &&
      hasAtLeastOneSpecial(val) &&
      hasAtLeastTwelveChars(val)
    );
  }
};