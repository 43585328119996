import React from "react";
import Billboard from "./billboard";
import verifyImage from "~/images/verify_account_illustration.png";

const Status = () => {
  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">
        <div className="form__registration animated fadeInUp--sm">
          <div className="notice__banner notice__banner--info">
            Your registration is under review. Upon successful validation of the account, you will receive an email with
            additional information on finalizing your registration.
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={verifyImage} style={{ width: 230, margin: "50px 0" }} />
          </div>
          <div className="form__group">
            <a href="/">
              <button type="submit" className="btn btn--fw">
                Done!
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
