import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FileUpload from "./file_upload";
import { Controller, useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";
import SubmitButton from "~/components/shared/SubmitButton";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#c1c9cf",
      fontWeight: 300,
      opacity: 1,
    },

    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const Form = ({ prospect_id, registrations }) => {
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  const mappedRegistrationArray = () => {
    const registrationArray = [];
    registrations.map((r) => {
      registrationArray[r.id] = {
        ein: r.ein,
        status: r.status,
      };
    });
    return registrationArray;
  };

  const defaultValues = {
    registrations: {
      attrs: mappedRegistrationArray(),
    },
  };

  const {
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues, mode: "all" });

  const watchAll = watch();

  useEffect(() => {
    const submittableRegistrations = getValues().registrations.attrs.filter(
      (r) => r.status === "unverified" || r.status === "declined"
    );
    const atLeastOneValid = submittableRegistrations.some((r) =>
      r.ein?.match(/\d\d\-\d\d\d\d\d\d\d/g)
    );
    const atLeastOneInvalid = submittableRegistrations.some(
      (r) => (r.ein?.length || 0) !== 0 && !r.ein.match(/\d\d\-\d\d\d\d\d\d\d/g)
    );
    setAllowSubmit(atLeastOneValid && !atLeastOneInvalid);
  }, [watchAll]);

  const renderStatusMessage = (registration) => {
    return (
      <div className="form__collection" key={registration.id}>
        <div className="form__collection__head">
          <div className="badge">{registration.entity}</div>
          <div className="form__collection__head__title mt--1">{registration.name}</div>
          <div className="form__collection__head__subtitle">{registration.address}</div>
        </div>
        {registration.status === "approved" ? (
          <div>Your documents have been approved.</div>
        ) : (
          <div>Your documents are in review.</div>
        )}
      </div>
    );
  };

  const renderForm = (registration) => {
    return (
      <div className="form__collection" key={registration.id}>
        <div className="form__collection__head">
          <div className="badge">{registration.entity}</div>
          <div className="form__collection__head__title mt--1">{registration.name}</div>
          <div className="form__collection__head__subtitle">{registration.address}</div>
        </div>
        <div className="form__group">
          <label className="form__label">Employer Identification Number (EIN)</label>
          <Controller
            name={`registrations[attrs][${registration.id}][ein]`}
            control={control}
            rules={{
              pattern: {
                value: /^(\d{2}-\d{7})$/i,
                message: "Invalid EIN",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <MaskedInput
                  mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  showMask={false}
                  value={value}
                  onChange={onChange}
                  render={(ref, props) => (
                    <CustomTextField
                      inputRef={ref}
                      placeholder="12-3456789"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      {...props}
                    />
                  )}
                />
                <input
                  type="hidden"
                  name={`registrations[attrs][${registration.id}][ein]`}
                  value={value}
                />
              </>
            )}
          />
          {errors.registrations?.attrs[registration.id]?.ein && (
            <div className="form__error--message">
              {errors.registrations.attrs[registration.id]?.ein.message}
            </div>
          )}
        </div>
        <div className="form__group">
          <label className="form__label">IRS EIN Letter (CP-575)</label>
          <FileUpload
            registration={registration}
            id={`ein_${registration.id}`}
            fileName="ein_letter"
            accept="image/*,.pdf"
          />
        </div>
        <div className="form__group">
          <label className="form__label">Articles of Incorporation</label>
          <FileUpload
            registration={registration}
            id={`aoi_${registration.id}`}
            fileName="article_of_incorporation"
            accept="image/*,.pdf"
          />
        </div>
      </div>
    );
  };

  const renderEntities = () => {
    return registrations.map((registration) => {
      if (registration.status === "unverified" || registration.status === "declined") {
        return renderForm(registration);
      } else {
        return renderStatusMessage(registration);
      }
    });
  };

  return (
    <form
      method="post"
      className="form__registration animated fadeInUp--sm"
      encType="multipart/form-data"
      action={`/enrollments/registration_details/${prospect_id}/edit`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="_method" value="patch" />
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector("[name=csrf-token]").content}
      />
      <div className="form__section">Registration Documents</div>
      <div className="form__group">{renderEntities()}</div>
      <div className="form__group">
        <SubmitButton isDisabled={!allowSubmit || isSubmitting} />
      </div>
    </form>
  );
};

export default Form;
