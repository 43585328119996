import React from "react";
import _ from "lodash";

import Errors from "./errors";

import { formatFilterKey } from "../utils/data_helpers";

const Notices = ({ data }) => {
  const formattedData = data?.flatMap((item) => item.formattedData);
  const isNotEmpty = _.compact(data?.flatMap((item) => item.column_mapping_warnings));

  if (data.length <= 0) {
    return null;
  }

  const renderNotice = () => {
    if (data && formattedData && formattedData.length === 0) {
      return renderMessageNothingToSubmit();
    } else {
      return (
        <Errors
          hasError={_.some(data, (row) => row.hasErrors)}
          mappingMismatch={_.some(data, (row) => row.hasErrors)}
          columnsWithErrors={_.uniq(_.flatMap(data, (row) => row.columns_with_errors))}
        />
      );
    }
  };

  const renderMessageNothingToSubmit = () => {
    return (
      <div className="notice__banner notice__banner--info notice__banner--m-btm">
        Nothing to submit - It looks like there are no matching rows in this file. Please confirm your NDCs and ensure
        that you have access and are authorized to submit on behalf of the Entity(s) listed in your file.
      </div>
    );
  };

  return <div>{renderNotice()}</div>;
};

export default Notices;
