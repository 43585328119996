import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Verify Your Email Address</div>
      <p>
        A one-time code was sent to the email address provided in your registration. Please enter the code to verify
        your email address. Not seeing it? Check your spam folder. The one-time code is different from the Enrollment
        ID. You will utilize the same Enrollment ID throughout registration.
      </p>
      <div className="t--bold">Need more time?</div>
      <p>Save your Enrollment ID to continue your registration process later.</p>
      <div className="mt--2 t--bold">Steps to Verify</div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">1</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Take note of your Enrollment ID</div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">2</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Visit your email to get your one-time code</div>
        </div>
      </div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">3</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Enter your one-time code to verify your email login</div>
        </div>
      </div>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
