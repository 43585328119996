import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Getting Started</div>
      <p>
        Begin the registration process by providing basic contact information and selecting one or multiple covered
        entities you are authorized to register. Following contact details verification, you will be required to provide
        documentation establishing your registration authorization. Documentation will be reviewed by Beacon Support and
        you will be notified of any additional information necessary to complete your registration.
      </p>
      <div className="t--bold">Please Note</div>
      <p>You should be prepared to provide the following documentation to complete the registration:</p>
      <ul className="t--bold">
        <li>Employer Identification Number (EIN)</li>
        <li>CP 575 (IRS letter stating the provision of the EIN)</li>
        <li>Articles of Incorporation</li>
      </ul>
      <p>
        You can revisit and continue the registration process by navigating to the Continue Registration page and
        entering your Enrollment ID.
      </p>
      <div className="mt--3 t--bold">Steps to Enroll</div>
      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">1</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Contact Details</div>
          <div className="billboard__step__item__info__subtitle">Provide your contact details</div>
        </div>
      </div>

      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">2</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Select Covered Entity(s)</div>
          <div className="billboard__step__item__info__subtitle">Select your Covered Entity(s) to register</div>
        </div>
      </div>

      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">3</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Signature Authorization</div>
          <div className="billboard__step__item__info__subtitle">Confirm authorization</div>
        </div>
      </div>

      <div className="billboard__step__item">
        <div className="billboard__step__item__icon">4</div>
        <div className="billboard__step__item__info">
          <div className="billboard__step__item__info__title">Terms & Privacy</div>
          <div className="billboard__step__item__info__subtitle">Review and accept terms</div>
        </div>
      </div>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
