import React from "react";
import BankDetailsModal from "../modals/bank_details_modal";
import VerifyDepositsModal from "../modals/verify_deposits_modal";
import BankAccountStatus from "./BankAccountStatus";
import BankAccountNotice from "./BankAccountNotice";

const BankDetails = ({ id_340b, access_role, bank_account }) => {
  const { id, status, name, routing_number, account_number } = bank_account;

  const renderModal = () => {
    if (access_role === "admin" && status === "unverified") {
      return <VerifyDepositsModal id_340b={id_340b} bank_account_id={id} />;
    }
    if (access_role === "admin" && status === "missing") {
      return <BankDetailsModal id_340b={id_340b} />;
    }
  };

  const maskedAccountNumber = account_number
    ? `**** **** **** ${account_number}`
    : null;

  const renderEntity = () => {
    return (
      <div className="list__item list__item--disable-hover">
        <div className="t__stack">
          <div className="t__stack__subtitle">Entity ID</div>
          <div className="t__stack__title t--sm">{id_340b}</div>
        </div>
        <div className="t__stack">
          <div className="t__stack__subtitle t--right">status</div>
          <BankAccountStatus status={status} />
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Account Name</div>
          <div className="t--sm">{name ?? "--"}</div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Routing Number</div>
          <div className="t--sm">{routing_number ?? "--"}</div>
        </div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Account Number</div>
          <div className="t--sm">{maskedAccountNumber ?? "--"}</div>
        </div>
      </>
    );
  };

  return (
    <div className="bank_details__container">
      <BankAccountNotice status={status} />
      <div className="bank_details__card">
        <div className="bank_details__card__header">{renderModal()}</div>
        <div className="bank_details__card__value">$0.00</div>
        <div className="bank_details__card__footer">
          <div className="t__stack">
            <div className="t__stack__subtitle t--white">Account</div>
            <div className="t__stack__title t--sm t--white">
              {maskedAccountNumber ?? "Pending"}
            </div>
          </div>
          <div className="t__stack">
            <div className="t__stack__subtitle t--white t--right">Entity</div>
            <div className="t__stack__title t--sm t--white">{id_340b}</div>
          </div>
        </div>
      </div>
      {renderEntity()}
      {renderDetails()}
    </div>
  );
};

export default BankDetails;
