import { useCallback, useRef } from "react";

export default class DiscardChangesHelper { 

  linkElementsList = [];
  linkSelection = '';
  setDiscardModal = () => {};

  // Make array of all link elements (and their children) on the page, to compare with later to check if user is trying to leave
  static getAllLinkElementsOnPage () {
    const aElements = Object.values(document.querySelectorAll('a'));

    // remove links that won't cause user to leave - download and new tab links
    const aLinkElements = aElements.filter((el) => !(el.hasAttribute("download") || el.getAttribute("target") == "_blank"));

    // make array of all 'a' elements and their children
    // return searchElementTrees(aLinkElements);
    this.linkElementsList = this.searchElementTrees(aLinkElements);
  }

  // Helper function to search through each 'a' element and add it and its children to an array
  static searchElementTrees = (aElements) => {
    const allElements = [];
    const queue = aElements;

    while(queue.length > 0){
      const current = queue.shift();

      if(current.childElementCount > 0) {
        for(let child of current.children){
          queue.push(child);
        }
      }
      allElements.push(current);
    }
    return allElements;
  }

  // Add event listeners sharing same controller, returns controller to remove them later
  // Takes in the display modal set function to show discard modal
  static addDiscardEventListeners = (setDisplayDiscardChangesModal) => {
    const controller = new AbortController();
    this.setDiscardModal = setDisplayDiscardChangesModal;
    window.addEventListener("click", (e) => this.clickHandler(e), { signal: controller.signal});
    window.addEventListener("beforeunload", (e) => this.beforeUnloadHandler(e), { signal: controller.signal});

    return controller;
  }
  
  // using provided controller removes listeners
  static removeDiscardEventListeners = (discardController) => {
    discardController ? discardController.abort() : "";
  }
  
  // Within the app, track elements user is clicking on, if clicked element is in linkElementsList then display modal
  static clickHandler = (e) => {
    if(this.linkElementsList.includes(e.target)){
  
      e.preventDefault();
      let anchor = e.target.closest("a");
      this.linkSelection = anchor.href; // store link user is clicking on
      this.setDiscardModal(true);
    }
  }
  
  // discard changes confirmation for browser controlled events
  static beforeUnloadHandler = (e) => {
    e.preventDefault();
    e.returnValue = true; // Included for legacy support, e.g. Chrome/Edge < 119
  }
  
  // user confirms in modal to leave the page
  static userLeave = (discardController) => {
    discardController ? discardController.abort() : ""; // remove browser redirect confirmation before leaving, cleanup
    location.href = this.linkSelection;
    this.setDiscardModal(false);
  }
}
