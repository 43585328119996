import React, { useState, useEffect } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { Close } from "@mui/icons-material";

const CustomSelectStyles = {
  container: (base) => ({
    ...base,
  }),
  control: (base) => ({
    ...base,
    borderColor: "#DEE7EE",
    margin: 0,
    borderRadius: 8,
    //boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.035)",
    minWidth: 130,
    minHeight: 32,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 12px",
    margin: 0,
    display: "block",
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: "Inter",
    fontSize: 12,
    paddingRight: 20,
    color: "#000",
    fontWeight: "600",
  }),
};


const SearchSelector = (props) => {
  const loadOptions = (query) => fetchSearchTerm(query);
  const [selected, setSelected] = useState(props.value);

  useEffect(() => {
    props.handleChange(props.label.toLowerCase(), selected?.value);
  }, [selected]);

  const handleChange = (event) => {
    setSelected(event);
  };

  const handleRemove = () => {
    setSelected();
    props.handleVisibility(props.label.toLowerCase(), false);
  };

  if (!props.visible && !props.locked) {
    return null;
  }

  const debouncedLoadOptions = debounce(loadOptions, 800, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL(props.url, window.location.origin);
    url.search = new URLSearchParams({
      query: query,
    }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const formatOptionLabel = ({ value, label }, { context }) => {
    if (context === "value") {
      return (
        <div className="filter__item__value">{label}</div>
      );
    } else if (context === "menu") {
      return (
        <div className="dialog__item">
          <span className="material-symbols-outlined">file_copy</span>
          {label}
        </div>
      );
    }
  };

  const renderRemove = () => {
    return (
        <div className="filter__item__remove button button--icon-tiny" onClick={() => handleRemove()}>
        <Close style={{ height: 14, width: 14 }} />
      </div>
    );
  }

  const Control = ({ children, ...props }) => {
    return (
      <components.Control className="filter__item" {...props}>
          <div className="filter__item__label">
            <span className="material-symbols-outlined">file_copy</span>
              Submission
            </div>
          <div className="filter__item__type">is</div>
          {children}
          {renderRemove()}
      </components.Control>
    );
  }

  const ValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer className="filter__item__value" {...props}>
        {children}
      </components.ValueContainer>
    );
  };

  return (
    <>
      <AsyncSelect
        name={props.label + "_id"}
        label={<div>props.label</div>}
        placeholder={"..."}
        noOptionsMessage={() => "Type to search.\nNo results found."}
        value={selected}
        //openMenuOnFocus={false}
        //openMenuOnClick={false}
        styles={CustomSelectStyles}
        //defaultOptions={false}
        isClearable={false}
        formatOptionLabel={formatOptionLabel}
        onChange={(event) => handleChange(event)}
        loadOptions={(query) => debouncedLoadOptions(query)}
        components={{ DropdownIndicator: null, IndicatorSeparator: null, Control, ValueContainer }}
      />
    </>
  );
};



export default SearchSelector;

