import React from "react";
import { datadogRum } from "@datadog/browser-rum";

const Datadog = (props) => {
  datadogRum.init({
    applicationId: props.datadog_application_id,
    clientToken: props.datadog_client_token,
    site: "us5.datadoghq.com",
    service: "beacon-rails",
    env: props.env,
    version: props.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  return <></>;
};

export default Datadog;
