import React, { useState, useEffect, useRef } from "react";
import _, { set } from "lodash";

const SubmissionTableFilter = ({ filter, setFilter, viewOriginal }) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();

  const filterOptions = {
    originalView: [
      { label: "All rows", filter: "all" },
      { label: "Only rows with errors", filter: "errors" },
      { label: "Only rows selected for submission", filter: "selected" },
      { label: "Only rows not selected for submission", filter: "not_selected" },
    ],
    submissionView: [
      { label: "All rows", filter: "all" },
      { label: "Only rows with errors", filter: "errors" },
    ],
  };

  const options = viewOriginal ? filterOptions.originalView : filterOptions.submissionView;

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    setFilter("all");
  }, [viewOriginal]);

  const handleClick = (item) => {
    setFilter(item.filter);
    setOpen(false);
  };

  const renderSelectedOption = () => {
    if (!viewOriginal && (filter != "all" && filter != "errors")) {
      setFilter("all");
      return options.find((option) => option.filter === "all").label;
    }

    return options.find((option) => option.filter === filter).label;
  };

  const renderDialog = () => {
    if (open) {
      return (
        <div ref={filterRef} className="dialog__container dialog--right">
          {renderItems()}
        </div>
      );
    }
  };

  const renderItems = () => {
    return _.map(options, (item) => {
      return (
        <div key={item.label} className="dialog__item" onClick={() => handleClick(item)}>
          <span className="material-symbols-outlined">list</span>
          {item.label}
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="button button--outlined" ref={filterRef} onClick={() => setOpen(!open)}>
        <span className="material-symbols-outlined">filter_list</span>
        <span style={{ marginRight: 5 }}>Display:</span>
        {renderSelectedOption()}
      </div>
      {renderDialog()}
    </div>
  );
};

export default SubmissionTableFilter;
