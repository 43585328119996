import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function BalancesTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex}) {

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor("id_340b", {
      header: "340B ID",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
    }),
    columnHelper.accessor("ndc", {
      header: "NDC",
      cell: (info) => info.getValue(),
      filterFn: "equalsString",
      sorting: false,
    }),
    columnHelper.accessor("claim_count", {
      header: "Unaccumulated Claim Qty",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("package_units", {
      header: "Claims Needed per Package",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("package_count", {
      header: "Unnacumulated Packages",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
  ];
});

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default BalancesTable;
