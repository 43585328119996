var warnings = {
  date_of_service: {
    title: "Date of Service",
    name: "date_of_service",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  date_prescribed: {
    title: "Date Prescribed",
    name: "date_prescribed",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  rx_number: {
    title: "Rx Number",
    name: "rx_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  ndc: {
    title: "NDC",
    name: "ndc",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  quantity: {
    title: "Quantity",
    name: "quantity",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  service_provider_id: {
    title: "Service Provider ID",
    name: "service_provider_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  contracted_entity_id: {
    title: "Contracted Entity ID",
    name: "contracted_entity_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  prescriber_id: {
    title: "Prescriber ID",
    name: "prescriber_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  prescriber_id_qualifier: {
    title: "Prescriber ID Qualifier",
    name: "prescriber_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  service_provider_id_qualifier: {
    title: "Service Provider ID Qualifier",
    name: "service_provider_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  wholesaler_invoice_number: {
    title: "Wholesaler Invoice Number",
    name: "wholesaler_invoice_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  plan_id_qualifier: {
    title: "Plan ID Qualifier",
    name: "plan_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  plan_id_code: {
    title: "Plan ID Code",
    name: "plan_id_code",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  plan_name: {
    title: "Plan Name",
    name: "plan_name",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  product_service_id_qualifier: {
    title: "Product Service ID Qualifier",
    name: "product_service_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  product_service_id: {
    title: "Product Service ID",
    name: "product_service_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  billed_hcpcs_quantity: {
    title: "Billed HCPCS Quantity",
    name: "billed_hcpcs_quantity",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  prescription_type: {
    title: "Prescription Type",
    name: "prescription_type",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  provider_claim_number: {
    title: "Provider Claim Number",
    name: "provider_claim_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  payor_claim_number: {
    title: "Payor Claim Number",
    name: "payor_claim_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  hcpcs_code: {
    title: "HCPCS Code",
    name: "hcpcs_code",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  hcpcs_code_modifier_1: {
    title: "HCPCS Code Modifier 1",
    name: "hcpcs_code_modifier_1",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  hcpcs_code_modifier_2: {
    title: "HCPCS Code Modifier 2",
    name: "hcpcs_code_modifier_2",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  billing_service_provider_id: {
    title: "Billing Service Provider ID",
    name: "billing_service_provider_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  billing_service_provider_id_qualifier: {
    title: "Billing Service Provider ID Qualifier",
    name: "billing_service_provider_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  date_of_encounter: {
    title: "Date of Encounter",
    name: "date_of_encounter",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
    },
  rendering_physician_id: {
    title: "Rendering Physician ID",
    name: "rendering_physician_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  rendering_physician_id_qualifier: {
    title: "Rendering Physician ID Qualifier",
    name: "rendering_physician_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  diagnosis_code1: {
    title: "Diagnosis Code 1",
    name: "diagnosis_code1",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  place_of_service_code: {
    title: "Place of Service Code",
    name: "place_of_service_code",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
};

export const mappingWarnings = () => {
  return warnings;
};
