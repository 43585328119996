import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function AccountsTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex}) {

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor("id", {
      header: "ID",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue().substring(0,12)}</div>,
    }),
    columnHelper.accessor("kind", {
      header: "Type",
      cell: (info) => info.getValue(),
      filterFn: "equalsString",
      sorting: false,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("remaining", {
      header: "Remaining Qty",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("date", {
      header: "Submitted Date",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
  ];
});

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default AccountsTable;
