import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import _ from "lodash";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

export const UserModal = (props) => {
  const { first_name, last_name, register, method, openModal, setOpenModal, errors } = props;

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Update Account Details</div>
        </div>
      </div>
    );
  };

  const renderModes = () => {
    return _.map(modes, (mode) => {
      return (
        <div
          className={
            selectedMode == mode.value
              ? "list__item list__item--selected list__item--no-mgn"
              : "list__item list__item--no-mgn list__item--align-right"
          }
          onClick={() => setSelectedMode(mode.value)}
        >
          {renderSelectedIndicator(mode)}
          {mode.label}
        </div>
      );
    });
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <div className="form__group">
          <label className="form__label">First Name</label>
          <CustomTextField
            placeholder="First Name"
            size="small"
            fullWidth={true}
            name="user[first_name]"
            defaultValue={first_name}
            variant="outlined"
            {...register("user[first_name]", {
              required: "Field is required",
            })}
            error={errors ? _.has(errors, "fist_name") : false}
          />
          {errors.fist_name && <div className="form__error--message">{errors.fist_name?.message}</div>}
        </div>
        <div className="form__group">
          <label className="form__label">Last Name</label>
          <CustomTextField
            placeholder="Last Name"
            size="small"
            name="user[last_name]"
            fullWidth={true}
            defaultValue={last_name}
            variant="outlined"
            {...register("user[last_name]", {
              required: "Field is required",
            })}
            error={errors ? _.has(errors, "last_name") : false}
          />
          {errors.last_name && <div className="form__error--message">{errors.last_name?.message}</div>}
        </div>

        {/* <div className="form__group">
          <label className="form__label">Mode</label>
          <div className="form__group__note">
            In Sandbox mode, you can test your claim and invoice files without processing real transactions.
          </div>
          <div className="container container--gap-md">{renderModes()}</div>
          <input type="hidden" name="user[mode]" value={selectedMode} />
        </div> */}

        <div className="form__group">
          <label className="form__label">Authentication</label>
          <div className="form__group__note">
            You will be required to verify you current MFA method before changing it.
          </div>
          <a href={`/mfa/${method}/verify?reset=true`}>
            <div className="button button--fw">Change MFA Method</div>
          </a>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--label" onClick={() => setOpenModal(false)}>
          Cancel
        </button>
        <button className="button" action="submit">
          Save Details
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (openModal) {
      return (
        <div
          className="modal__background"
          onPointerDown={(e) => {
            e.target.className == "modal__background" && setOpenModal(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form method="post" action="/user" encType="multipart/form-data">
              <input type="hidden" name="_method" value="put" />
              <input
                type="hidden"
                name="authenticity_token"
                value={document.querySelector("[name=csrf-token]").content}
              />
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return renderModal();
};
