import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";
import CloseIcon from "@mui/icons-material/Close";
import { ModalHeaderImage } from "../../images/svgs";

const CofirmationModal = (props) => {
  const renderHeader = () => {
    return (
      <div className="modal__content__header">
        <div
          className="modal__content__header--container flex justify-center"
          style={{
            background: "#F2F5F8",
            height: 60,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div style={{ marginBottom: -10 }}>
            <ModalHeaderImage />
          </div>
        </div>
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div
        className="btn btn--sqr btn--sqr--modal"
        onClick={() => props.setDisplayModal(false)}
      >
        <CloseIcon className="t--sm" />
      </div>
    );
  };
  const renderBody = () => {
    return (
      <div className="flex flex-col item-start" style={{ marginTop: 65 }}>
        <div className="t--xl t--primary mt-1" style={{ fontSize: "24px" }}>
          {props.title}
        </div>

        <div className="modal__content__body">{props.body}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div className="modal modal__footer p--0">{props.footer}</div>;
  };

  return (
    <div style={{overflow: 'visible'}} className="modal" data-testid="modal-continer">
      <div
        className="modal"
        style={{ width: props.width ? props.width : "680px" }}
      >
        <div className="modal">
          {renderClose()}
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default CofirmationModal;
