import React from "react";
import { Skeleton } from "@mui/material";

const HeaderSkeleton = () => {
  return (
    <>
      <div className="container container--space-between">
      <div className="container container--columns container--shrink">
        <Skeleton animation="wave" sx={{fontSize: 14, width: 80}}></Skeleton>
        <div className="t__stack">
          <Skeleton animation="wave" sx={{fontSize: 14, width: 300}}></Skeleton>
          <Skeleton animation="wave" sx={{fontSize: 12, width: 240}}></Skeleton>
        </div>
      </div>
      <div className="container container--gap-lg" style={{ marginBottom: 10 }}>
        <div className="t__stack container--align-end">
          <div className="t__stack__subtitle t--right">Claim Balance</div>
          <Skeleton animation="wave" sx={{fontSize: 18, width: "50%"}}></Skeleton>
        </div>
        <div className="t__stack container--align-end">
          <div className="t__stack__subtitle t--right">Package Balance</div>
          <Skeleton animation="wave" sx={{fontSize: 18, width: "50%"}}></Skeleton>
        </div>
        <div className="t__stack container--align-end">
          <div className="t__stack__subtitle t--right">Credit Balance</div>
          <Skeleton animation="wave" sx={{fontSize: 18, width: "100%"}}></Skeleton>
        </div>
      </div>
    </div>
    <div className="container container--end container--mb-md">
        <button className={`button button--outlined button--outlined--disabled`}>
          <div className="material-symbols-outlined">download</div>
          Download Report
        </button>
    </div>
    </>
  );
};

export default HeaderSkeleton;
