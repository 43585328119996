import React from "react";

const Header = (props) => {
  const initials = props.prospect.first_name[0] + props.prospect.last_name[0]
  return (
    <div className="row">
      <div className="col-6 bg-light-gray"></div>
      <div className="col-6">
        <div className="head__container">
          <div className="avatar__container">
            <div className="avatar">{initials.toUpperCase()}</div>
            <div className="avatar__details">
              <div className="avatar__details__name">{props.prospect.first_name}</div>
              <div className="avatar__details__email">
                {props.prospect.email}
              </div>
            </div>
          </div>

          <div className="head__items">
            <button className="btn btn--label">Resources</button>
            <button className="btn btn--outline">Save and Exit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
