import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Notice from "/components/shared/Notice";
import FormInput from "/components/shared/FormInput";

const Form = ({ enrollment_id }) => {
  const [notice, setNotice] = useState({
    kind: "success",
    open: false,
    message: "Email successfully sent",
  });

  const {
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });

  const handleResendEmail = () => {
    if (notice.open) return;

    document.getElementById("resend-form").submit();

    setNotice({ ...notice, open: true });

    setTimeout(() => {
      setNotice({ ...notice, open: false });
    }, 5000);
  };

  return (
    <>
      <form method="post" action={`${window.location.href}/validate`}>
        <input type="hidden" name="authenticity_token" value={document.querySelector("[name=csrf-token]").content} />
        <div className="form__registration animated fadeInUp--sm">
          <div className="notice__banner notice__banner--warning mt-2">
            This is your Enrollment ID: <span style={{ color: "#000000" }}>{enrollment_id}</span>. Please make note of
            your ID and save this as it will be required to continue your enrollment process.
          </div>
          <div className="form__section mt--2">One-Time Code</div>
          <FormInput
            placeholder="Enter One-Time Code"
            label="Enter the one-time code sent to your email below."
            name="confirmations[code]"
            errors={errors}
            register={register}
            registerOptions={{
              required: "Field is required",
            }}
          >
            <div onClick={handleResendEmail} className="t--link mt--1">
              Resend email
            </div>
          </FormInput>
          <div className="form__group">
            <button type="submit" className={"btn btn--fw " + (isValid ? "" : "btn--disabled")} disabled={!isValid}>
              Verify
            </button>
          </div>
        </div>
      </form>
      <form method="post" id="resend-form" action={`${window.location.href}/resend`}>
        <input type="hidden" name="authenticity_token" value={document.querySelector("[name=csrf-token]").content} />
      </form>
      <Notice details={notice} />
    </>
  );
};

export default Form;
