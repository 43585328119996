import React, { useState, useEffect } from "react";

const Tabs = (props) => {
  const renderTabs = () => {
    return props.items.map((item) => (
      <div
        key={item.id}
        className={item.id == props.selectedTab ? "button button--label button--label--active" : "button button--label"}
        onClick={() => {
          props.setSelectedTab(item.id);
        }}
      >
        {item.label}
      </div>
    ));
  };

  return <div className="tab_bar">{renderTabs()}</div>;
};
export default Tabs;
