import React, { useRef, useEffect, useState, useCallback } from "react";
import _ from "lodash";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import useVirtualScrollAndResizeHandling from "../../hooks/useVirtualScrollAndResizeHandling";

import TableCell from "./table_cell";
import HeaderCell from "./header_cell";
import EmptyTablePlaceholder from "./empty_table_placeholder";

const SubmissionTable = (props) => {
  const parentRef = useRef(null);
  const scrollableRef = useRef(null);
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  const columnHelper = createColumnHelper();

  const headers = props.headers.filter((item) => item.id !== "beacon_id");

  const columns = _.map(headers, (header) => {
    return columnHelper.accessor(header, {
      header: ({}) => {
        if (viewOriginal) {
          return header;
        }
        return _.find(props.columnDetails, { name: header }).title;
      },
      id: header,
      cell: ({ row, cell }) => {
        return (
          <TableCell key={cell.id} row={row.original} column={cell.column.id} isOriginalData={props.viewOriginal} />
        );
      },
    });
  });

  const table = useReactTable({
    data: props.tableData.map((item) => {
      return {
        ...item,
        data: Object.fromEntries(Object.entries(item.data).filter(([key]) => key !== "beacon_id")),
      };
    }),
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: props.tableData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 10,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  const virtualSize = rowVirtualizer.getTotalSize();

  useVirtualScrollAndResizeHandling(parentRef, tableRef, virtualSize, virtualItems, props.tableData);

  const renderEmpty = () => {
    return (
      <div ref={parentRef} className="virtual__table__claims-output__container">
        <table className="primary data">
          {renderColumnHeaders()}
          {/* <tbody>{_.times(15, (i) => generateEmptyRow(i))}</tbody> */}
        </table>
      </div>
    );
  };

  // const generateEmptyRow = (index) => {
  //   return (
  //     <tr key={index} className="data-table__row">
  //       <td colSpan={props.headers.length} />
  //     </tr>
  //   );
  // };

  const renderData = () => {
    if (_.isEmpty(props.mapping)) {
      return (
        <div ref={parentRef} className="virtual__table__claims-output__container">
          <table className="primary data">{renderColumnHeaders()}</table>
          <EmptyTablePlaceholder noColumnMapping={true} setSelectedTab={props.setSelectedTab} />
        </div>
      );
    }
    if (props.tableData.length > 0) {
      return (
        <div ref={parentRef} className="virtual__table__claims-output__container">
          <div ref={scrollableRef} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            <table ref={tableRef} className="primary data">
              {renderColumnHeaders()}
              <tbody className={props.viewOriginal ? "no-alt-bg" : ""}>{renderVirtualRows()}</tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div ref={parentRef} className="virtual__table__claims-output__container">
          <table className="primary data">{renderColumnHeaders()}</table>
          <EmptyTablePlaceholder noFilterMatches={true} setSelectedTab={props.setSelectedTab} />
        </div>
      );
    }
  };

  const renderColumnHeaders = () => {
    return (
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="virtual__table__sticky-header" key={headerGroup.id}>
            {headerGroup.headers
              .filter((item) => item.id !== "beacon_id")
              .map((header) => {
                if (["claim_conforms_flag", "formatted_rx_number"].includes(header.id)) {
                  return;
                }

                return (
                  <HeaderCell
                    key={header.id}
                    header={header}
                    title={
                      props.viewOriginal ? header.id : props.columnDetails.find((h) => h.name === header.id)?.title
                    }
                    viewOriginal={props.viewOriginal}
                    warnings={props.columnMappingWarnings}
                    mapping={props.mapping}
                    mapped={_.values(props.mapping?.mappings).includes(header.id)}
                  />
                );
              })}
          </tr>
        ))}
      </thead>
    );
  };

  const renderVirtualRows = () => {
    return rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
      const row = rows[virtualRow.index];
      return (
        <tr
          key={index}
          className={props.viewOriginal ? "no-alt-bg" : ""}
          style={{
            height: `${virtualRow.size}px`,
            transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
          }}
        >
          {renderCells(row)}
        </tr>
      );
    });
  };

  const renderCells = (row) => {
    return row
      .getVisibleCells()
      .filter((cell) => {
        return !["formatted_rx_number", "claim_conforms_flag", "beacon_id"].includes(cell.column.id);
      })
      .map((cell) => {
        // const style = !inValid ? "data-table__row__item" : "data-table__row__item data-table__row__item--invalid";

        return flexRender(cell.column.columnDef.cell, cell.getContext());
      });
  };

  if (!props.tableData.length) {
    return renderEmpty();
  }

  return renderData();
};

export default SubmissionTable;
