import React, { useState } from "react";
import _ from "lodash";

const Entity = ({ items, value, handleChange }) => {
  const [open, setOpen] = useState(false);

  const renderDialog = () => {
    if (open) {
      return <div className="dialog__container dialog--right">{renderItems()}</div>;
    }
  };

  const renderItems = () => {
    return _.map(items, (item) => {
      return (
        <div key={item.label} className="dialog__item" onClick={() => handleChange("entity", item.value)}>
          <span className="material-symbols-outlined">home_work</span>
          {item.label}
        </div>
      );
    });
  };

  return (
    <div className="filter__item" onClick={() => setOpen(!open)}>
      <div className="filter__item__label">
        <span className="material-symbols-outlined">home_work</span>
        Entity
      </div>

      <div className="filter__item__type">IS</div>
      <div className="filter__item__value">{value}</div>
      {renderDialog()}
    </div>
  );
};

export default Entity;
