import React from "react";
import _ from "lodash";

import { truncateString } from "../../utils/data_helpers";

const TableCell = ({ row, column, isOriginalData }) => {
  const getClassName = (row, columnName, originalData) => {
    if (originalData) {
      return getOriginalDataClassName(row, columnName, row.columns_with_errors[_.findKey(row.mapping, function (mapping) { return mapping == columnName; })]);
    }

    return getClaimDataClassName(row, columnName, row.columns_with_errors[columnName]);
  };

  const getClaimDataClassName = (row, column, invalidCell) => {
    if (invalidCell) {
      return "invalid";
    }
  };

  const getOriginalDataClassName = (row, column, invalidCell) => {
    let isMappedColumn = _.values(row.mapping).includes(column);

    if (invalidCell) {
      return "invalid";
    }

    if (row.selected && isMappedColumn) {
      return "mapped";
    }

    if (row.selected) {
      return "selected";
    }
  };

  const getHeaderValue = () => {
    if (isOriginalData) {
      return row.data[column];
    }

    return row.data[column];
  };

  const classList = () => {
    if (getClassName(row, column, isOriginalData)) {
      return `border ${getClassName(row, column, isOriginalData)}`;
    } else {
      return "border";
    }
  };

  return (
    <td className={classList()}>
      <div>{truncateString(getHeaderValue(), 18)}</div>
    </td>
  );
};

export default TableCell;
