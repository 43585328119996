import {
  isValidInvoiceQuantity,
  isValidNPI,
  isValidContractedEntityID,
  isValidInvoiceDate,
  isValidAccountNumber,
  isValidWholesalerName,
  isValidInvoiceNumber,
} from "./data_validations";
import { isRequiredField } from "../schemas/invoice";

const isValidInvoiceField = (value, column, row, mapping) => {
  if (isRequiredField(column) && !value) {
    return false;
  }

  var cleanValue = value?.trim();

  switch (column) {
    case "invoice_number":
      return isValidInvoiceNumber(cleanValue);
    case "invoice_date":
      return isValidInvoiceDate(cleanValue);
    case "ndc":
      return true;
    case "contracted_entity_id":
      return isValidContractedEntityID(cleanValue);
    case "service_provider_id":
      return isValidNPI(cleanValue);
    case "quantity":
      return isValidInvoiceQuantity(cleanValue);
    case "account_number":
      return isValidAccountNumber(cleanValue);
    case "wholesaler_name":
      return isValidWholesalerName(cleanValue);
  }
};

export default isValidInvoiceField;
