import React, { useState, useRef } from 'react';
import { styled } from "@mui/material/styles";
import Popover from '@mui/material/Popover';

/*  Custom popover
*   Value is the displayed content popover is anchored to
*   popOverContent is content of the popover when displayed
*   if hoverable set to true, onClickCallback can be passed in when popover is clicked
*   event propagation is disabled by default, can be passed in to enable
*/

const CustomPopover = ({value, popoverContent, hoverable = false, onClickCallback = undefined, propagate = false}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const anchorEl = useRef(null)

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const CustomPopover = styled(Popover)({
    "& .MuiPaper-root": {
      pointerEvents: hoverable ? "auto" : "none",
      cursor: 'pointer',
      border: "1px solid #DEE5EB",
      boxShadow: "none",
      padding: "5px",
    },
  });

  const onClickHandler = (e) => {
    propagate ? "" : e.stopPropagation();
    onClickCallback();
  }

  return (
    <div>
      <div
        ref={anchorEl}
        aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {value}
      </div>
      <CustomPopover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={popoverOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{ paper: { onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose, onClick: (e) => onClickHandler(e) } }}
      >
        {popoverContent}
      </CustomPopover>
    </div>
  );
}

export default CustomPopover;