import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import debounce from "debounce-promise";
import moment from "moment";
import Draw from "./draw";
import ClaimDetailsTable from "./disputes_table";
import Notice from "/components/shared/Notice";
import Http from "../reports/http";
import StatCards from "./cards";

const ReportsIndex = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id");
  const [issueList, setIssueList] = useState([]);
  const [stats, setStats] = useState({});
  const [selectedDispute, setSelectedDispute] = useState(null);

  const [filterQuery, setFilterQuery] = useState({
    product_name: null,
    status: null,
  });
  const [arrayFilterQuery, setArrayFilterQuery] = useState({});
  // TODO: We currently end up running the query on first load 4 times, once with the initial values and then for each update
  // to the filters (id_340b, manufacturer, date_range). We should get this so it only needs to happen once.
  const [searchQuery, setSearchQuery] = useState({});
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const isMounted = useRef(false);

  const memoQuery = useMemo(() => {
    return searchQuery;
  }, [props, searchQuery]);

  const getIndicatorColor = (status) => {
    switch (status.toLowerCase()) {
      case "under review":
        return "blue";
      case "accepted":
        return "green";
      case "rejected":
        return "red";
      default:
        return "grey";
    }
  };

  const refresh = () => {
    setIsClaimsLoading(true);
    Http.newFetchQuery(props.query_path, memoQuery).then((res) => {
      setClaims(res.claims);
      setIsClaimsLoading(false);
    });
  };

  useEffect(() => {
    setIsClaimsLoading(true);
    debouncedFetchHandler(props.query_path, memoQuery).then((res) => {
      setClaims(res.claims);
      setIsClaimsLoading(false);
    });
    //fetch();
  }, [memoQuery]);

  useEffect(() => {
    if ((isMounted.current && !claims) || (isMounted.current && claims.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }
    var issues = _.uniqWith(_.flatten(_.map(claims, (c) => c.issues)), _.isEqual);
    setIssueList(issues);

    isMounted.current = true;
  }, [claims]);

  const debouncedFetchHandler = debounce(Http.newFetchQuery, 100);

  const handleFilter = (name, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const handleArrayFilter = (name, value) => {
    setArrayFilterQuery({ ...arrayFilterQuery, [name]: value });
  };

  const handleSearch = (name, value) => {
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const getFilters = () => {
    var filters = {};

    _.each(filterQuery, (value, key) => {
      if (value && value.length) _.assign(filters, { [key]: value });
    });

    return filters;
  };

  const filterArrayFields = () => {
    var filtClaims = claims;
    _.each(arrayFilterQuery, (value, key) => {
      if (value && value.length) {
        filtClaims = _.filter(filtClaims, (c) => {
          return _.includes(
            c[key].map((i) => i.value),
            value
          );
        });
      }
    });
    return filtClaims;
  };

  const filteredClaims = () => {
    var filtClaims = filterArrayFields();
    return _.filter(filtClaims, getFilters());
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container">
        <div className="page-details__container">
          <div className="page-details__container__info">
            <div className="page-details__title">Dispute Center</div>
            <div className="breadcrumbs__container">
              <div className="breadcrumbs__crumb breadcrumbs__crumb__active">Reports</div>
            </div>
          </div>
        </div>
        <ClaimDetailsTable
          claims={filteredClaims()}
          isLoading={isClaimsLoading}
          setDispute={setSelectedDispute}
          dispute={selectedDispute}
          getIndicatorColor={getIndicatorColor}
        />
      </div>
      <Draw refresh={refresh} dispute={selectedDispute} getIndicatorColor={getIndicatorColor} />
      <Notice details={notice} />
    </div>
  );
};

export default ReportsIndex;
