import React from "react";
import BillboardSupportFooter from "/components/shared/BillboardSupportFooter";

const Billboard = ({ kind }) => {
  const renderBillboard = () => {
    return renderSetupBillboard();
  };

  const renderSetupBillboard = () => {
    return (
      <>
        <div className="mt--2 t--bold">Select Your Preferred MFA Method:</div>
        <ul>
          <li>
            <div className="billboard__step__item">
              <div className="billboard__step__item__info">
                <div className="billboard__step__item__info__title">
                  SMS Text: This option sends a one-time code to your mobile device that will need
                  to be entered into the site each time you log in.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="billboard__step__item">
              <div className="billboard__step__item__info">
                <div className="billboard__step__item__info__title">
                  Email: This option sends a one-time code to your email that will need to be
                  entered into the site each time you log in.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="billboard__step__item">
              <div className="billboard__step__item__info">
                <div className="billboard__step__item__info__title">
                  Authenticator App: This option requires that you utilize an authenticator app to
                  generate a one-time code that will need to be entered into the site each time you
                  log in.
                </div>
              </div>
            </div>
          </li>
        </ul>
      </>
    );
  };

  return (
    <div className="billboard__container animated fadeInUp--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Select Your Multi-Factor Authentication Method</div>
      <p>
        As an additional measure of security, Beacon utilizes multi-factor authentication (MFA) each
        time a user logs in to their Beacon account. This sends a one-time code to you each time you
        log in. Please select your preferred delivery method for your one-time code: SMS text,
        email, or through an authenticator app.
      </p>
      {renderBillboard()}
      <div className="mt--4 t--light">Need help?</div>
      <p className="t--italic t--subtitle">
        <a href="https://support.beaconchannelmanagement.com/en/articles/9588593-how-to-establish-your-multi-factor-authentication-mfa">
          Learn more about How to Establish Your Multi-Factor Authentication (MFA) in the Beacon
          Support Center.
        </a>
      </p>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;
