import React from 'react';

const BillboardStepItem = ({ num, title, subtitle = "" }) => {
  return (
    <div className="billboard__step__item">
      <div className="billboard__step__item__icon">{num}</div>
      <div className="billboard__step__item__info">
        <div className="billboard__step__item__info__title">{title}</div>
        {subtitle && <div className="billboard__step__item__info__subtitle">{subtitle}</div>}
      </div>
    </div>
  );
}

export default BillboardStepItem;
