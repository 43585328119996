import React from "react";
import { Skeleton } from "@mui/material";

const CardSkeleton = ({ rows }) => {
  return [...Array(rows)].map((_, i) => (
    <div
    className={
      "list__item list__item--disabled list__item--disable-hover flex--dir-col"
    }
  >
    <div className="list__item__footer">
      <Skeleton animation="wave" sx={{fontSize: 12, width: "50%", textAlign: 'left'}}></Skeleton>
    </div>
    <Skeleton animation="wave" sx={{fontSize: 16, width: "100%"}}></Skeleton>
    <div className="list__item__footer">
      <Skeleton animation="wave" sx={{fontSize: 12, width: "35%"}}></Skeleton>
      <Skeleton animation="wave" sx={{fontSize: 12, width: "35%"}}></Skeleton>
    </div>
  </div>
  ));
};

export default CardSkeleton;
