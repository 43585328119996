import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import Table from "/components/shared/table";

function MembersTable({ team_members }) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("full_name", {
        header: "Name",
        sorting: false,
        cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("role", {
        header: "Role",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  return (
    <div>
      <Table
        data={team_members}
        columns={columns}
        enableHover={true}
        noResultsMessage="The current search and filter criteria returned no results"
        layoutFixed={true}
      />
    </div>
  );
}

export default MembersTable;
