import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import Table from "/components/shared/table";

function EntityDetailsTable({ pharmacies }) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("name", {
        header: "Pharmacy",
        sorting: false,
        cell: (info) => (
          <>
            <div>{info.getValue()}</div>
            <div className="t--light">{info.row.original.address}</div>
          </>
        ),
      }),
      columnHelper.accessor("npis", {
        header: () => <span className="no-caps">NPIs</span>,
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("manufacturers", {
        header: "Manufacturer",
        cell: (info) =>
          info.getValue().map((manufacturer) => {
            return <p key={manufacturer}>{manufacturer}</p>;
          }),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  return (
    <div className="table--scroll">
      <Table
        data={pharmacies}
        columns={columns}
        enableHover={true}
        noResultsMessage="The current search and filter criteria returned no results"
        layoutFixed={true}
      />
    </div>
  );
}

export default EntityDetailsTable;
