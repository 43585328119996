import React from "react";

const BankAccountStatus = ({ status }) => {
  const displayStatus = (status) => {
    switch (status) {
      case "in_review":
        return "In Review";
      case "bank_info_approved":
        return "In Review";
      case "unverified":
        return "Pending Verification";
      case "active":
        return "Active";
      case "missing":
        return "Missing Details";
      default:
        return "Contact Support";
    }
  };

  return (
    <div className={`t__stack__title t--sm ${status == "active" ? "t--blue" : "t--orange"}`}>
      {displayStatus(status)}
    </div>
  );
};

export default BankAccountStatus;
