import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { get } from "react-hook-form";

const FileUpload = ({
  name,
  label,
  required = false,
  multiple = false,
  control,
  errors = {},
}) => {
  const errorForName = get(errors, name);
  const [files, setFiles] = useState([]);

  const handleFiles = (field) => (e) => {
    setFiles(Array.from(e.target.files).map(file => ({ name: file.name, type: file.type })));
    field.onChange(e.target.files);
  };

  const renderFile = (file) => (
    <div className="file_upload__file" key={file.name}>
      <div className="file_upload__icon">
        <span className="material-symbols-outlined">description</span>
      </div>
      <div className="file_upload__details">
        <div className="file_upload__details__title">{file?.name}</div>
        <div className="file_upload__details__subtitle">{file?.type}</div>
      </div>
    </div>
  );

  const renderFiles = () => {
    if (files.length > 0) {
      return <div>{files.map((file) => renderFile(file))}</div>;
    }

    if (multiple) {
      return <div className="file_upload__note">Please select files to upload</div>;
    } else {
      return <div className="file_upload__note">Please select a file to upload {required ? '- this field is required' : ''}</div>;
    }
  };

  return (
    <div className="form__group">
      <label className="form__label">{label}</label>
      <div className="file_upload">
        {renderFiles()}
        <div className="file_upload__button">
          <button type="button" className="btn btn--outline" onClick={() => document.getElementById(name).click()}>
            <span className="material-symbols-outlined">post_add</span>
            {multiple ? 'Select Files' : 'Select File'}
          </button>
        </div>
        <Controller
           control={control}
           name={name}
           rules={required ? { required: 'This field is required.' } : undefined}
           render={({ field })=> (
             <input
               type="file"
               name={name}
               id={name}
               required={required}
               multiple={multiple}
               style={{display: "none"}}
               onChange={handleFiles(field)}
               aria-invalid={errorForName}
             />
           )}
         />
         {errors && errorForName && (
           <div className="form__error--message">{errorForName["message"]}</div>
         )}
      </div>
    </div>
  );
};

export default FileUpload;
